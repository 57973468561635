import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import decode from 'jwt-decode'
import { REHYDRATE } from 'redux-persist/lib/constants';
import {
  HOME_GET_AUTHRIZATION,
} from './constants';

export function getAuthrization(auth) {
  return {
    type: HOME_GET_AUTHRIZATION,
    data: auth,
  };
}

export function useGetAuthrization() {
  const dispatch = useDispatch();
  const auth = useSelector(state => state.home.auth);

  const updateAuthrization = useEffect(() => {
    if (window.location.href.indexOf('id_token') > -1) {
      let newAuth = JSON.parse(JSON.stringify(auth));
      const idToken = window.location.href.match(/(id_token)\=([\S\s]*?)\&/)[2];//eslint-disable-line
      const user = decode(idToken).email;
      const userId = decode(idToken).identities[0].userId;
      newAuth.cognitoIdToken = idToken;
      newAuth.validUser = true;
      newAuth.user = user;
      const index = userId ? userId.indexOf('@') : -1;
      const netWorkId = index > -1 ? userId.slice(0, index) : '';
      newAuth.userId = netWorkId;
      dispatch(getAuthrization(newAuth));
    }
  }, [dispatch, window.location.href.indexOf('id_token')]); //eslint-disable-line

  return { auth, updateAuthrization };
}

export function reducer(state, action) {
  switch (action.type) {
    case HOME_GET_AUTHRIZATION:
      return {
        ...state,
        auth: action.data,
      };
    case REHYDRATE:
      const payloadData = action.payload;
      if (payloadData) {
        const authData = payloadData.home;
        if(authData) {
          return {
            ...state,
            auth: authData.auth,
          };
        }
      }
      return state;
 
    default:
      return state;
  }
}
