export const GET_ORDER_TOKEN_BEGIN = 'GET_ORDER_TOKEN_BEGIN';
export const GET_ORDER_TOKEN_SUCCESS = 'GET_ORDER_TOKEN_SUCCESS';
export const GET_ORDER_TOKEN_FAILURE = 'GET_ORDER_TOKEN_FAILURE';

export const GET_ACCOUNT_TOKEN_BEGIN = 'GET_ACCOUNT_TOKEN_BEGIN';
export const GET_ACCOUNT_TOKEN_SUCCESS = 'GET_ACCOUNT_TOKEN_SUCCESS';
export const GET_ACCOUNT_TOKEN_FAILURE = 'GET_ACCOUNT_TOKEN_FAILURE';

export const HOME_GET_AUTHRIZATION = 'HOME_GET_AUTHRIZATION';

export const GET_USBL_OGM_TOKEN_BEGIN = 'GET_USBL_OGM_TOKEN_BEGIN';
export const GET_USBL_OGM_TOKEN_SUCCESS = 'GET_USBL_OGM_TOKEN_SUCCESS';
export const GET_USBL_OGM_TOKEN_FAILURE = 'GET_USBL_OGM_TOKEN_FAILURE';
export const GET_SALESFORCE_TOKEN_BEGIN = 'GET_SALESFORCE_TOKEN_BEGIN';
export const GET_SALESFORCE_TOKEN_SUCCESS = 'GET_SALESFORCE_TOKEN_SUCCESS';
export const GET_SALESFORCE_TOKEN_FAILURE = 'GET_SALESFORCE_TOKEN_FAILURE';
export const GET_WILLCALLTIMES_TOKEN_BEGIN = 'GET_WILLCALLTIMES_TOKEN_BEGIN';
export const GET_WILLCALLTIMES_TOKEN_SUCCESS = 'GET_WILLCALLTIMES_TOKEN_SUCCESS';
export const GET_WILLCALLTIMES_TOKEN_FAILURE = 'GET_WILLCALLTIMES_TOKEN_FAILURE';
export const GET_ORDERREFERENCE_TOKEN_BEGIN = 'GET_ORDERREFERENCE_TOKEN_BEGIN';
export const GET_ORDERREFERENCE_TOKEN_SUCCESS = 'GET_ORDERREFERENCE_TOKEN_SUCCESS';
export const GET_ORDERREFERENCE_TOKEN_FAILURE = 'GET_ORDERREFERENCE_TOKEN_FAILURE';
export const AUTH_ERROR_MSG = 'Authorization required.';
export const GET_ASOH_TOKEN_BEGIN = 'GET_ASOH_TOKEN_BEGIN';
export const GET_ASOH_TOKEN_SUCCESS = 'GET_ASOH_TOKEN_SUCCESS';
export const GET_ASOH_TOKEN_FAILURE = 'GET_ASOH_TOKEN_FAILURE';