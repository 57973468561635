// This is the JSON way to define React Router rules in a Rekit app.
// Learn more from: http://rekit.js.org/docs/routing.html

import PlaceOrder from './PlaceOrder';

export default {
  path: '',
  childRoutes: [{
    path: 'place-order',
    component: PlaceOrder,
    isIndex: true
  }],
};
