import React from 'react';
import { Result } from 'antd';

export default function ErrorPage() {
  return (
    <div className="common-error-page">
      <Result
        className='result-panel'
        status="500"
        title="500"
        subTitle="Sorry, something went wrong. Please try again."
      />
    </div>
  );
};

ErrorPage.propTypes = {};
ErrorPage.defaultProps = {};
