export const SUPC_ONLY_NUMBER_ALLOWED = 'SUPC should be only numeric type!';
export const ITEM_STATUS_CODES =['A','N'];
export const ITEMS_STOCK_INDICATOR ='S';
export const ITEM_STATUS_ERROR_MSG ='This item is not Active';
export const ITEM_STOCK_INDICATOR_ERROR_MSG ='This Item is not Stocked';
export const SHIP_TO_CUSTOMER_STATUS =['NW','AC','ST'];
export const SHIP_TO_CUSTOMER_STATUS_ERROR_MSG ='This Customer is not Active';
export const INVALID_CUSTOMER_ERROR_MSG ='Invalid customer number';
export const INVALID_SUPC_ERROR_MSG ='Invalid SUPC!';
export const DUPLICATE_SUPC_ERROR_MSG ='Duplicate SUPC!';
export const INVALID_CHART_ERROR_MSG ='Proprietary Item not in Overide chart';
export const DATE_NEEDED_VALUE_IS_EMPTY = 'Please select Date Needed';
export const INVALID_WILL_CALL_TIME = 'Orders for will call must be placed by 12pm for same day pickup';
export const DATE_NEEDED_VALUE_IS_CURRENT_TODAY = 'For Delivery Method - Route to Customer, the Date needed cannot be on the same day';
export const INVALID_WILL_CALL_TIME_TODAY_AFTER_TWELVE_PM = 'Orders for will call must be placed before 12pm for same day pickup';
export const INVALID_WILL_CALL_TIME_TODAY_NO_DATA = 'There is no available time slot';
export const BOTH_CASE_SPLIT_ZERO_ERROR_MSG = 'Case and Split cannot be 0';
export const ONLY_CASE_ENABLED_ZERO_ERROR_MSG = 'Case cannot be 0 for Case Only Item';
export const ONLY_SPLIT_ENABLED_ZERO_ERROR_MSG = 'Split cannot be 0 for Split Only Item';
export const NO_VALID_ITEMS_ERROR_MSG ='No valid items';
export const CAN_NOT_ADD_MULTIPLE_EMPTY_ITEM_ERROR_MSG ='Cannot add multiple empty item!';
export const ADD_ITEM_INFO_MSG ='Please add item!';
export const SUPPLIER_ACCOUNT_STATUS =['S','I'];
export const SUPPLIER_ACCOUNT_ERROR_MSG ='Invalid Broker Account Number';
export const NETWORK_ERROR_MSG ='Network error occurred. Please try again. Contact server administrator if the error persists.';
export const CREATE_MULE_ORDER_ERROR_MSG ='Order submission failed!';
export const CREATE_MULE_ORDER_SUCCESS_MSG ='Order submitted successfully!';
export const DELIVERY_METHOD_ROUTE_TO_CUSTOMER = '0';
export const DELIVERY_METHOD_WILLCALL = '3';
export const DELIVERY_METHOD_WILLCALL_SAME_TODAY = '8';
export const NO_INVENTORY_AVAILABLE ='No Inventory Available.';
export const DOM_ORDER_RESPONSE ='Vendor Sample Order Reference No: # has submitted successfully';
