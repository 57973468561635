import axios from 'axios';
import { API_REQUEST_URL } from '../../../common/api';
import {
    CREATE_ORDER_ITEM_BEGIN,
    CREATE_ORDER_ITEM_SUCCESS,
    CREATE_ORDER_ITEM_FAILURE,
} from './constants';


export function createOrderItem(data = {}) {
    return (dispatch) => {
        dispatch({
            type: CREATE_ORDER_ITEM_BEGIN,
        });

        const promise = new Promise((resolve, reject) => {
            const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
            const doRequest = axios.post(`${API_REQUEST_URL}/salesforceOrder/createOrderItem`, data, {
                headers: {
                    'Authorization': token
                }
            });
            doRequest.then(
                (res) => {
                    dispatch({
                        type: CREATE_ORDER_ITEM_SUCCESS,
                        data: res.data,
                    });
                    resolve(res);
                },
                (err) => {
                    const errorMsg = err && err.response ? err.response.data : 'There is an error. Please refresh page or contact administrator.';
                    dispatch({
                        type: CREATE_ORDER_ITEM_FAILURE,
                        data: { error: errorMsg },
                    });
                    reject(err);
                },
            );
        });

        return promise;
    };
}
export function reducer(state, action) {
    switch (action.type) {
        case CREATE_ORDER_ITEM_BEGIN:
            return {
                ...state,
                createOrderItemPending: true,
                createOrderItemError: null,
            };

        case CREATE_ORDER_ITEM_SUCCESS:
            return {
                ...state,
                orderItem: action.data,
                createOrderItemPending: false,
                createOrderItemError: null,
            };

        case CREATE_ORDER_ITEM_FAILURE:
            return {
                ...state,
                createOrderItemPending: false,
                createOrderItemError: action.data.error,
            };

        default:
            return state;
    }
}
