import axios from 'axios';
import { API_REQUEST_URL } from '../../../common/api';
import _ from 'lodash';
import {
  FETCH_BULK_ASOH_BEGIN,
  FETCH_BULK_ASOH_SUCCESS,
  FETCH_BULK_ASOH_FAILURE,
  REMOVE_ASOH_ERROR_MSG,
  RESET_ALL_ITEM_QUANTITY,
} from './constants';
import { NO_INVENTORY_AVAILABLE } from '../component/MessageConstants';
import { addAsohErrorMsg } from './sortObject';
import { caseOrSplit, convertCaseToSplitQuatity, formatBulkAsohMessage } from './formatData';
import { DOM_VIEW_NAME_LOCATION } from '../../../common/constants';

export function fetchBulkASOH(args={}) {
  return (dispatch,getState) => {
    dispatch({
      type: FETCH_BULK_ASOH_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const token = `Bearer ${window.sessionStorage.getItem("asohToken")}`;
      const domToken = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
      const doRequest = axios.post(`${API_REQUEST_URL}/order/fetchAsoh?viewName=${args.businessUnitNumber}-${DOM_VIEW_NAME_LOCATION}`,args,{
        headers: {
          'Authorization': token,
          'cognitoToken': cognitoToken,
          'dom-Authorization': domToken,
        }
      });
      doRequest.then(
        (res) => {
          let asohData = [];
          if(!_.isEmpty(res.data) && !_.isEmpty(res.data.products)){
            asohData = res.data.products;
          }
          dispatch({
            type: FETCH_BULK_ASOH_SUCCESS,
            data: asohData,
          });
          resolve(res);
        },
        (err) => {
          const errorMsg = err && err.response ? err.response.data ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.':'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: FETCH_BULK_ASOH_FAILURE,
            data: { error: errorMsg },
            requestData: args
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_BULK_ASOH_BEGIN:
      return {
        ...state,
        fetchBulkAsohPending: true,
        fetchBulkAsohError: null,
      };

    case FETCH_BULK_ASOH_SUCCESS:
      let products = JSON.parse(JSON.stringify(state.products));
      let asohData = action.data;
      let errorMsg = [];
      const isDomSite = state.isDomSite;
      formatBulkAsohMessage(products,asohData,errorMsg,isDomSite);
      return {
        ...state,
        asohData: action.data,
        asohValidationMsg: errorMsg,
        fetchBulkAsohPending: false,
        fetchBulkAsohError: null,
      };

    case FETCH_BULK_ASOH_FAILURE:
      let failedErrorMsg = [];
      formatBulkAsohMessage(JSON.parse(JSON.stringify(state.products)),[],failedErrorMsg,state.isDomSite,action.requestData.products,action.data.error);
      return {
        ...state,
        fetchBulkAsohPending: false,
        fetchBulkAsohError: action.data.error,
        asohValidationMsg: failedErrorMsg,
      };
    default:
      return state;
  }
}
