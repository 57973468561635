export const FETCH_CUSTOMER_INFO_BEGIN = 'FETCH_CUSTOMER_INFO_BEGIN';
export const FETCH_CUSTOMER_INFO_SUCCESS = 'FETCH_CUSTOMER_INFO_SUCCESS';
export const FETCH_CUSTOMER_INFO_FAILURE = 'FETCH_CUSTOMER_INFO_FAILURE';
export const CLEAR_CUSTOMER_INFO = 'CLEAR_CUSTOMER_INFO';

export const FETCH_SUPPLIER_INFO_BEGIN = 'FETCH_SUPPLIER_INFO_BEGIN';
export const FETCH_SUPPLIER_INFO_SUCCESS = 'FETCH_SUPPLIER_INFO_SUCCESS';
export const FETCH_SUPPLIER_INFO_FAILURE = 'FETCH_SUPPLIER_INFO_FAILURE';
export const CLEAR_SUPPLIER_INFO = 'CLEAR_SUPPLIER_INFO';

export const FETCH_PRODUCT_INFO_BEGIN = 'FETCH_PRODUCT_INFO_BEGIN';
export const FETCH_PRODUCT_INFO_SUCCESS = 'FETCH_PRODUCT_INFO_SUCCESS';
export const FETCH_PRODUCT_INFO_FAILURE = 'FETCH_PRODUCT_INFO_FAILURE';
export const ADD_EMPTY_PRODUCT_INFO = 'ADD_EMPTY_PRODUCT_INFO';
export const REMOVE_ONE_PRODUCT = 'REMOVE_ONE_PRODUCT';
export const CLEAR_PRODUCT_INFO = 'CLEAR_PRODUCT_INFO';
export const SET_ITEM_QUANTITY_BEGIN = 'SET_ITEM_QUANTITY_BEGIN';
export const SET_ITEM_QUANTITY_SUCCESS = 'SET_ITEM_QUANTITY_SUCCESS';

export const PLACE_ORDER_FETCH_OPCO_DATA_BEGIN = 'PLACE_ORDER_FETCH_OPCO_DATA_BEGIN';
export const PLACE_ORDER_FETCH_OPCO_DATA_SUCCESS = 'PLACE_ORDER_FETCH_OPCO_DATA_SUCCESS';
export const PLACE_ORDER_FETCH_OPCO_DATA_FAILURE = 'PLACE_ORDER_FETCH_OPCO_DATA_FAILURE';
export const CLEAR_OPCO_INFO = 'CLEAR_OPCO_INFO';
export const UPDATE_DELIVERY_METHOD = 'UPDATE_DELIVERY_METHOD';
export const CLEAR_DELIVERY_METHOD = 'CLEAR_DELIVERY_METHOD';

export const RESET_INVALID_PRODUCT = 'RESET_INVALID_PRODUCT';
export const REPLACE_PRODUCT = 'REPLACE_PRODUCT';

export const FETCH_OVERRIDE_CHART_REPORT_BEGIN = 'FETCH_OVERRIDE_CHART_REPORT_BEGIN';
export const FETCH_OVERRIDE_CHART_REPORT_SUCCESS = 'FETCH_OVERRIDE_CHART_REPORT_SUCCESS';
export const FETCH_OVERRIDE_CHART_REPORT_FAILURE = 'FETCH_OVERRIDE_CHART_REPORT_FAILURE';
export const FETCH_OVERRIDE_CHART_DETAIL_BEGIN = 'FETCH_OVERRIDE_CHART_DETAIL_BEGIN';
export const FETCH_OVERRIDE_CHART_DETAIL_SUCCESS = 'FETCH_OVERRIDE_CHART_DETAIL_SUCCESS';
export const FETCH_OVERRIDE_CHART_DETAIL_FAILURE = 'FETCH_OVERRIDE_CHART_DETAIL_FAILURE';
export const PLACE_ORDER_BEGIN = 'PLACE_ORDER_BEGIN';
export const PLACE_ORDER_SUCCESS = 'PLACE_ORDER_SUCCESS';
export const PLACE_ORDER_FAILURE = 'PLACE_ORDER_FAILURE';
export const FETCH_PRICE_BOOK_ENTRY_BEGIN = 'FETCH_PRICE_BOOK_ENTRY_BEGIN';
export const FETCH_PRICE_BOOK_ENTRY_SUCCESS = 'FETCH_PRICE_BOOK_ENTRY_SUCCESS';
export const FETCH_PRICE_BOOK_ENTRY_FAILURE = 'FETCH_PRICE_BOOK_ENTRY_FAILURE';
export const CREATE_ORDER_HEADER_BEGIN = 'CREATE_ORDER_HEADER_BEGIN';
export const CREATE_ORDER_HEADER_SUCCESS = 'CREATE_ORDER_HEADER_SUCCESS';
export const CREATE_ORDER_HEADER_FAILURE = 'CREATE_ORDER_HEADER_FAILURE';

export const CREATE_ORDER_ITEM_BEGIN = 'CREATE_ORDER_ITEM_BEGIN';
export const CREATE_ORDER_ITEM_SUCCESS = 'CREATE_ORDER_ITEM_SUCCESS';
export const CREATE_ORDER_ITEM_FAILURE = 'CREATE_ORDER_ITEM_FAILURE';

export const FETCH_WILL_CALL_TIMES_BEGIN = 'FETCH_WILL_CALL_TIMES_BEGIN';
export const FETCH_WILL_CALL_TIMES_SUCCESS = 'FETCH_WILL_CALL_TIMES_SUCCESS';
export const FETCH_WILL_CALL_TIMES_FAILURE = 'FETCH_WILL_CALL_TIMES_FAILURE';
export const CREATE_MULE_ORDER_BEGIN = 'CREATE_MULE_ORDER_BEGIN';
export const CREATE_MULE_ORDER_SUCCESS = 'CREATE_MULE_ORDER_SUCCESS';
export const CREATE_MULE_ORDER_FAILURE = 'CREATE_MULE_ORDER_FAILURE';
export const CLEAR_PICK_UP_TIME = 'CLEAR_PICK_UP_TIME';
export const CLEAR_CREATE_ORDER_ERROR = 'CLEAR_CREATE_ORDER_ERROR';
export const CLEAR_MULE_ORDER='CLEAR_MULE_ORDER';
export const GET_REFERENCE_ID_BEGIN = 'GET_REFERENCE_ID_BEGIN';
export const GET_REFERENCE_ID_SUCCESS = 'GET_REFERENCE_ID_SUCCESS';
export const GET_REFERENCE_ID_FAILURE = 'GET_REFERENCE_ID_FAILURE';

export const FETCH_SITE_DATA_BEGIN = 'FETCH_SITE_DATA_BEGIN';
export const FETCH_SITE_DATA_SUCCESS = 'FETCH_SITE_DATA_SUCCESS';
export const FETCH_SITE_DATA_FAILURE = 'FETCH_SITE_DATA_FAILURE';
export const FETCH_SITE_DATA_DISMISS_ERROR = 'FETCH_SITE_DATA_DISMISS_ERROR';
export const ITEM_SEARCH_FETCH_SINGLE_REQUEST_DATA_RESET = 'ITEM_SEARCH_FETCH_SINGLE_REQUEST_DATA_RESET';
export const ITEM_SEARCH_SUBMIT_BULK_UPLOAD_BEGIN = 'ITEM_SEARCH_SUBMIT_BULK_UPLOAD_BEGIN';
export const ITEM_SEARCH_SUBMIT_BULK_UPLOAD_SUCCESS = 'ITEM_SEARCH_SUBMIT_BULK_UPLOAD_SUCCESS';
export const ITEM_SEARCH_SUBMIT_BULK_UPLOAD_FAILURE = 'ITEM_SEARCH_SUBMIT_BULK_UPLOAD_FAILURE';
export const ITEM_SEARCH_SUBMIT_BULK_UPLOAD_DISMISS_ERROR = 'ITEM_SEARCH_SUBMIT_BULK_UPLOAD_DISMISS_ERROR';
export const ITEM_SEARCH_DOWNLOAD_SINGLE_REQUEST_FILE_BEGIN = 'ITEM_SEARCH_DOWNLOAD_SINGLE_REQUEST_FILE_BEGIN';
export const ITEM_SEARCH_DOWNLOAD_SINGLE_REQUEST_FILE_SUCCESS = 'ITEM_SEARCH_DOWNLOAD_SINGLE_REQUEST_FILE_SUCCESS';
export const ITEM_SEARCH_DOWNLOAD_SINGLE_REQUEST_FILE_FAILURE = 'ITEM_SEARCH_DOWNLOAD_SINGLE_REQUEST_FILE_FAILURE';
export const ITEM_SEARCH_DOWNLOAD_SINGLE_REQUEST_FILE_DISMISS_ERROR = 'ITEM_SEARCH_DOWNLOAD_SINGLE_REQUEST_FILE_DISMISS_ERROR';
export const ITEM_SEARCH_DOWNLOAD_BULK_UPLOAD_TEMPLATE_BEGIN = 'ITEM_SEARCH_DOWNLOAD_BULK_UPLOAD_TEMPLATE_BEGIN';
export const ITEM_SEARCH_DOWNLOAD_BULK_UPLOAD_TEMPLATE_SUCCESS = 'ITEM_SEARCH_DOWNLOAD_BULK_UPLOAD_TEMPLATE_SUCCESS';
export const ITEM_SEARCH_DOWNLOAD_BULK_UPLOAD_TEMPLATE_FAILURE = 'ITEM_SEARCH_DOWNLOAD_BULK_UPLOAD_TEMPLATE_FAILURE';
export const ITEM_SEARCH_DOWNLOAD_BULK_UPLOAD_TEMPLATE_DISMISS_ERROR = 'ITEM_SEARCH_DOWNLOAD_BULK_UPLOAD_TEMPLATE_DISMISS_ERROR';
export const ITEM_SEARCH_FETCH_BRAND_DATA_BEGIN = 'ITEM_SEARCH_FETCH_BRAND_DATA_BEGIN';
export const ITEM_SEARCH_FETCH_BRAND_DATA_SUCCESS = 'ITEM_SEARCH_FETCH_BRAND_DATA_SUCCESS';
export const ITEM_SEARCH_FETCH_BRAND_DATA_FAILURE = 'ITEM_SEARCH_FETCH_BRAND_DATA_FAILURE';
export const ITEM_SEARCH_FETCH_BRAND_DATA_DISMISS_ERROR = 'ITEM_SEARCH_FETCH_BRAND_DATA_DISMISS_ERROR';
export const ITEM_SEARCH_BRAND_DATA_RESET = 'ITEM_SEARCH_BRAND_DATA_RESET';
export const ITEM_SEARCH_FETCH_SINGLE_REQUEST_DATA_BEGIN = 'ITEM_SEARCH_FETCH_SINGLE_REQUEST_DATA_BEGIN';
export const ITEM_SEARCH_FETCH_SINGLE_REQUEST_DATA_SUCCESS = 'ITEM_SEARCH_FETCH_SINGLE_REQUEST_DATA_SUCCESS';
export const ITEM_SEARCH_FETCH_SINGLE_REQUEST_DATA_FAILURE = 'ITEM_SEARCH_FETCH_SINGLE_REQUEST_DATA_FAILURE';
export const ITEM_SEARCH_FETCH_SINGLE_REQUEST_DATA_DISMISS_ERROR = 'ITEM_SEARCH_FETCH_SINGLE_REQUEST_DATA_DISMISS_ERROR';

export const FETCH_ASOH_BEGIN ='FETCH_ASOH_BEGIN';
export const FETCH_ASOH_SUCCESS ='FETCH_ASOH_SUCCESS';
export const FETCH_ASOH_FAILURE ='FETCH_ASOH_FAILURE';
export const REMOVE_ASOH_ERROR_MSG ='REMOVE_ASOH_ERROR_MSG';
export const RESET_ALL_ITEM_QUANTITY ='RESET_ALL_ITEM_QUANTITY';
export const FETCH_BULK_ASOH_BEGIN ='FETCH_BULK_ASOH_BEGIN';
export const FETCH_BULK_ASOH_SUCCESS ='FETCH_BULK_ASOH_SUCCESS';
export const FETCH_BULK_ASOH_FAILURE ='FETCH_BULK_ASOH_FAILURE';
export const RESET_ASOH_ERROR_MSG ='RESET_ASOH_ERROR_MSG';
export const ORDER_RESET_PICK_UP_TIME = 'ORDER_RESET_PICK_UP_TIME';
export const FETCH_IS_DOM_SITE_BEGIN ='FETCH_IS_DOM_SITE_BEGIN';
export const FETCH_IS_DOM_SITE_SUCCESS ='FETCH_IS_DOM_SITE_SUCCESS';
export const FETCH_IS_DOM_SITE_FAILURE ='FETCH_IS_DOM_SITE_FAILURE';


