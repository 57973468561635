import React from 'react';
import { Form, Row, Col, Button, Alert, Modal, Spin, message, Tooltip } from 'antd';
import moment from 'moment';
import OrderDetail from './OrderDetail';
import OrderHeader from './OrderHeader';
import {
  clearCustomerInfo, clearSupplierInfo, clearDeliveryMethod, clearProductInfo, fetchPriceBookEntry,
  createOrderHeader, createOrderItem, submitOrder, createMuleOrder, clearPickUpTime, getReferenceId,fetchASOH, clearCreateOrderError, clearMuleOrder
} from './redux/actions';
import { connect } from 'react-redux';
import _ from 'lodash';
import { DATE_FROMAT_HH_MM_SS, DATE_FROMAT_MM_DD_YYYY, DATE_FROMAT_MM_DD_YYYY_HH_MM_SS, DATE_FROMAT_YYYY_MM_DD, FIVE_DIGIT_MAX_NUM, FIVE_DIGIT_MIN_NUM } from '../../common/constants';
import { ADD_ITEM_INFO_MSG, BOTH_CASE_SPLIT_ZERO_ERROR_MSG, CREATE_MULE_ORDER_ERROR_MSG, CREATE_MULE_ORDER_SUCCESS_MSG, DELIVERY_METHOD_ROUTE_TO_CUSTOMER, DELIVERY_METHOD_WILLCALL, DELIVERY_METHOD_WILLCALL_SAME_TODAY, DOM_ORDER_RESPONSE, INVALID_WILL_CALL_TIME_TODAY_AFTER_TWELVE_PM, NO_VALID_ITEMS, NO_VALID_ITEMS_ERROR_MSG, ONLY_CASE_ENABLED_ZERO_ERROR_MSG, ONLY_SPLIT_ENABLED_ZERO_ERROR_MSG } from './component/MessageConstants';
import { truncate } from './component/CommonUtils';
import { isTodayAndAfterNoon, isTodayOrNextDay } from './componentUtils';
class PlaceOrderComponent extends React.Component {

  constructor(props) {
    super(props);
    this.orderHeader = React.createRef();
    this.handleFinish = this.handleFinish.bind(this);
    this.onReset = this.onReset.bind(this);
    this.clearForm = this.clearForm.bind(this);
    // this.addTodo = this.addTodo.bind(this);
    this.state = {
      // opcoId: '',
      // supplierAccount: '',
      routeCustomerId: '',
      duplicateProduct: [],
      itemErrorMesg: '',
      quantityError: null,
      formError: null,
      errorProduct:[],
      asohError: null,
      afterThreshold:false,
    }
  }

  form = React.createRef();
  componentWillReceiveProps(nextProps) {
    const { muleOrder,createMuleOrderError} = nextProps;
    if (this.props.muleOrder !== muleOrder && !_.isEmpty(muleOrder)) {
      this.popupModal(CREATE_MULE_ORDER_SUCCESS_MSG,muleOrder.message);
    }else if(!_.isEmpty(createMuleOrderError)){
      this.popupModal(CREATE_MULE_ORDER_ERROR_MSG,CREATE_MULE_ORDER_ERROR_MSG);
    }
  }
  onOK = () => {
    const {muleOrder,clearCreateOrderError,createMuleOrderError,clearMuleOrder} = this.props;
    if(!_.isEmpty(muleOrder)){
      this.onReset();
      clearMuleOrder();
    }
    if(!_.isEmpty(createMuleOrderError)){
      clearCreateOrderError();
    }
  }
  hideModal =()=>{
    this.setState({
      visible: false,
    });
  }
  popupModal = (title,message) => {
    let modal = Modal.info({
      centered: true,
      closable: true,
      visible: this.state.visible,
      title: title,
      content: (
        <div style={{ marginTop: 20 }}>
          <p>{message}</p>
        </div>
      ),
      okText: 'Ok',
      okType: 'green',
      onOk: () => {
        this.hideModal();
        this.onOK();
      },
      onCancel:()=>{
        this.hideModal();
        this.onOK();
      }
    });
    return modal;
  }
  formatPickupTime = (field,value) =>{
    if(_.isEmpty(value)){
      return "";
    }
    if(field === 'routeName'){
      return value.indexOf('-') > -1 && value.slice(0,value.indexOf('-'));
    }
    if(field === 'pickupTime'){
      return value.indexOf('-') > -1 && value.slice(value.indexOf('-')+1,value.length);
    }
  }
  handleFinish = (values) => {
    const { products, createMuleOrderPending, muleOrder, dispatch, createMuleOrder,getReferenceId } = this.props;
    const { setFieldsValue } = this.form.current;
    if(this.state.formError !== null){
      return;
    }
    if(isTodayAndAfterNoon(values.dateNeeded,moment(moment().format(DATE_FROMAT_MM_DD_YYYY) + ' 12:00:00', DATE_FROMAT_MM_DD_YYYY_HH_MM_SS))){
      setFieldsValue({'pickUpTime': null});
      message.error(INVALID_WILL_CALL_TIME_TODAY_AFTER_TWELVE_PM);
    }else if (products.length === 1 && products[0].id === '') {
      message.error(ADD_ITEM_INFO_MSG);
    } else if (this.state.quantityError !== null) {
      message.error(this.state.quantityError);
    } else {
      const orderDetail = this.getOrderDetails(products);
      if(_.isEmpty(orderDetail) && orderDetail.length === 0){
        message.error(NO_VALID_ITEMS_ERROR_MSG);
        return;
      }
      // createMuleOrder(this.getOrderData(values, products,orderDetail));
      getReferenceId(this.getOrderData(values, products,orderDetail));
    }
  }
  getOrderData = (values, products,orderDetail) => {
    const { auth } = this.props;
    return {
      vendorCustomerNo: values.supplierAccount, //supplier Account 
      deliveryDate: moment(values.dateNeeded).format(DATE_FROMAT_YYYY_MM_DD),  //Date Needed
      orderDate: moment().format(DATE_FROMAT_YYYY_MM_DD),   //Current Date
      orderTime: moment().format(DATE_FROMAT_HH_MM_SS), // Current Time
      suffix: "01",       //always 01
      susOrderNumber: "00000000",  //always 00000000
      site: values.site,          //"010"
      seperateInvoiceFlag: "Y",  //always 'N'
      deliveryType: this.getDeliveryType(values),
      withNextOrder: "N",   // always 'N'
      customerPO: "",    //always sampledemo
      uomNumber: null, //this.getUomNumber(),    //5 digit random no
      totalLines: orderDetail.length,    //// total no of Supcs
      totalQuantity: this.getTotalQuantity(products),   //Sum of all quantity in detail.
      deliveryCustomerNo: this.formattedFormData(values.customerNumber, ''),   // Route to customer number.
      userID: truncate(auth.userId,25),   //logged userId,
      emailID: truncate(auth.user,100),    //logged user email
      orderNotes: !_.isEmpty(this.formattedFormData(values.note, ''))
        ?this.formattedFormData(values.note, '').trim():this.formattedFormData(values.note, ''),   //notes
      detail: orderDetail,
      vendor: this.getVendor(auth, values)
    }
  }
  getUomNumber = () => {
    return parseInt(Math.random() * (FIVE_DIGIT_MAX_NUM - FIVE_DIGIT_MIN_NUM + 1) + FIVE_DIGIT_MIN_NUM);
  }
  formattedFormData = (data, initData) => {
    let formData = data;
    if (data === undefined) {
      formData = initData;
    }
    return formData;
  }
  getVendor = (auth, values) => {
    return {
      emailID: truncate(auth.user,100),  //the logged in User email
      contactName: !_.isEmpty(this.formattedFormData(values.contactName, ''))
      ?this.formattedFormData(values.contactName, '').trim():this.formattedFormData(values.contactName, ''),  //Map the Contact Name field data here
      contactNumber: !_.isEmpty(this.formattedFormData(values.contactPhone, ''))
      ?this.formattedFormData(values.contactPhone, '').trim():this.formattedFormData(values.contactPhone, ''),   //Map the Contact Phone field data here
      routeName:  this.formatPickupTime('routeName',this.formattedFormData(values.pickUpTime, '') ) //pick up time's value
    }
  }
  getOrderDetails = (products) => {
    let orderDetail = [];
    let lineNumber = 1;
    products.map(item => {
      if (_.isEmpty(item.cs) && _.isEmpty(item.spl)) {

      }
    });
    products.map(item => {
      if(!_.isEmpty(item.id) && !isNaN(item.id)){     
        if (item.cs !== '' && item.cs !== null && item.cs !== "0") {
          orderDetail.push({
            itemNumber: item.id,
            lineNumber: parseInt(lineNumber),      //to change according to ship_splits_only and minimum_split 
            splitCode: false,   //need to change according to ship_splits_only and minimum_split 
            quantity: parseInt(item.cs)    //need to change according to ship_splits_only and minimum_split  
          })
          lineNumber = parseInt(lineNumber) + 1;
        }
        if (item.spl !== '' && item.spl !== null && item.spl !== "0") {
          orderDetail.push({
            itemNumber: item.id,
            lineNumber: parseInt(lineNumber),      //to change according to ship_splits_only and minimum_split 
            splitCode: true,   //need to change according to ship_splits_only and minimum_split 
            quantity: parseInt(item.spl)    //need to change according to ship_splits_only and minimum_split  
          })
          lineNumber = parseInt(lineNumber) + 1;
        }
      }
    })
    return orderDetail;
  }
  getTotalQuantity = (products) => {
    let totalQuantity = 0;
    products.map(item => {
      if(!_.isEmpty(item.id) && !isNaN(item.id)){
        let oneQuantity = parseInt(item.cs === '' || item.cs === null ? 0 : item.cs) + parseInt(item.spl === '' || item.spl === null ? 0 : item.spl);
        totalQuantity = parseInt(oneQuantity) + parseInt(totalQuantity);
      }
    });
    return totalQuantity;
  }
  getDeliveryType = (values) => {
    if(_.isEmpty(values.deliveryMethod)){
      return values.deliveryMethod;
    }
    return values.deliveryMethod === 'routetoCustomer'? DELIVERY_METHOD_ROUTE_TO_CUSTOMER :
            values.deliveryMethod === 'willCall' && values.dateNeeded.isSame(moment().format(DATE_FROMAT_YYYY_MM_DD),'day') ? DELIVERY_METHOD_WILLCALL_SAME_TODAY :
            values.deliveryMethod === 'willCall'? DELIVERY_METHOD_WILLCALL :
            ""
  }
  onReset = () => {
    const { clearSupplierInfo, clearCustomerInfo, clearDeliveryMethod, clearProductInfo, clearPickUpTime } = this.props;
    this.form.current.resetFields();
    clearSupplierInfo();
    clearCustomerInfo();
    clearDeliveryMethod();
    clearProductInfo();
    clearPickUpTime();
    this.setState({
      ...this.state,
      routeCustomerId: '',
      duplicateProduct: [],
      itemErrorMesg: '',
      quantityError: null,
      formError: null,
      errorProduct: []
    })
  };
  handleChange(field, value) {
    this.setState({
      ...this.state,
      [field]: value
    })
  }
  validateItemQuantity = (field, value, record) => {
    // if( record.cs === '' && record.spl === ''){
    //   return;
    // }
    //add inventory validation
    
    if (record.isShipSplitOnly && field === 'spl' && value === "0") {
      this.handleChange("quantityError", ONLY_SPLIT_ENABLED_ZERO_ERROR_MSG)
    } else if (!record.isShipSplitOnly && record.minimumSplit === 0 && field === 'cs' && value === "0") {
      this.handleChange("quantityError", ONLY_CASE_ENABLED_ZERO_ERROR_MSG)
    } else {
      if (field === 'cs') {
        parseInt(value) + parseInt(record.spl) === 0 ?
          this.handleChange("quantityError", BOTH_CASE_SPLIT_ZERO_ERROR_MSG)
          : this.handleChange("quantityError", null);
      }
      if (field === 'spl') {
        parseInt(record.cs) + parseInt(value) === 0 ?
          this.handleChange("quantityError", BOTH_CASE_SPLIT_ZERO_ERROR_MSG)
          : this.handleChange("quantityError", null);
      }
    }
  }
  clearForm =(exceptField) =>{
    const {clearSupplierInfo,clearCustomerInfo,clearDeliveryMethod,clearProductInfo,clearPickUpTime } = this.props;
    if(exceptField === 'supplierAccount'){
      //this.form.current.resetFields(['pickUpTime','customerNumber'],'');
    }else if(exceptField === 'site'){
      this.form.current.resetFields(['supplierAccount','dateNeeded','pickUpTime','customerNumber'],'');
      clearSupplierInfo();
      clearCustomerInfo();
      clearPickUpTime();
    }
    clearDeliveryMethod();
    clearProductInfo();
    this.setState({
      ...this.state,
        duplicateProduct: [],
        itemErrorMesg: '',
        quantityError: null,
        formError: null,
        errorProduct: [],
      });
  }
  validateASOH =(value,splitFlag,record,opco)=>{
    const { fetchAsoh } = this.props;
    fetchAsoh(opco,record.id,value,splitFlag,record.index,record);
  }
  render() {
    const {
      itemError,
      createOrderHeaderPending,
      itemSucc,
      products,
      fetchProductInfoError,
      fetchProductInfoPending,
      createOrderItemPending,
      invalidProducts,
      fetchOverrideChartDetailPending,
      fetchOverrideChartReportPending,
      priceBookEntryPending,
      setItemQuantityPending,
      createMuleOrderPending,
      muleOrder,
      overrideChartReport,
      fetchOverrideChartReportError,
      overrideChartDetail,
      fetchOverrideChartDetailError,
      asohValidationMsg,
      fetchAsohPending,
      fetchBulkAsohPending,
      fetchReferenceIdPending,
      getOrderTokenPending,
      getAsohTokenPending,
    }
      = this.props;
    return (
      <div className="place-order">
        <div className="place-order-panel">
          <div className="font-size-24" style={{ paddingBottom: 18 }}>
            Place Order
          </div>
          <Spin tip="" spinning={createMuleOrderPending || fetchProductInfoPending || fetchAsohPending 
            || fetchBulkAsohPending || fetchReferenceIdPending 
            || getOrderTokenPending || getAsohTokenPending }>
          <div className="place-order-header-panel">
            <Form
              name="order-header"
              className="order-header-form"
              size="middle"
              onFinish={this.handleFinish}
              ref={this.form}
            >
              <div className="order-header-panel">
                <OrderHeader form={this.form} 
                  duplicateProduct={this.state.duplicateProduct} handleChange={(field, value) => this.handleChange(field, value)} 
                  formatPickupTime={this.formatPickupTime} formError={this.state.formError} onClearForm={(exceptField) =>this.clearForm(exceptField)}
                  afterThreshold={this.state.afterThreshold}
                  />
              </div>
              
                <div className="order-detail-panel">
                  <OrderDetail form={this.form} duplicateProduct={this.state.duplicateProduct} handleChange={(field, value) => this.handleChange(field, value)}
                    products={products} fetchProductInfoPending={fetchProductInfoPending} invalidProducts={invalidProducts} itemErrorMesg={this.state.itemErrorMesg}
                    quantityError={this.state.quantityError} validateItemQuantity={(field, value, record) => this.validateItemQuantity(field, value, record)} 
                    errorProduct={this.state.errorProduct} validateASOH={(value,splitFlag,record,opco)=>this.validateASOH(value,splitFlag,record,opco)}
                    />
                  {/* <EditableTable /> */}
                </div>
                <br />
                <div>
                  <Row justify="center">
                    <Col span={6}></Col>
                    <Col span={8}>
                      <Tooltip title={this.state.quantityError}>
                        <Button
                          type="green"
                          htmlType="submit"
                          style={{ marginRight: 25 }}
                          disabled={(products.length === 1 && products[0].id === '') || products.length === 0
                            || (invalidProducts.length !== 0) || fetchProductInfoPending
                            || fetchOverrideChartReportPending || fetchOverrideChartDetailPending
                            || this.state.duplicateProduct.length !== 0 || setItemQuantityPending
                            || this.state.quantityError !== null || this.state.formError !== null
                            || !_.isEmpty(this.state.errorProduct)
                            || (!_.isEmpty(asohValidationMsg))
                            || this.state.afterThreshold
                            // || !_.isEmpty(fetchAsohError)
                          }
                        >
                          Submit
                        </Button>
                      <Button onClick={this.onReset}>Reset</Button>
                      </Tooltip>
                    </Col>
                  </Row>
                </div>
              
            </Form>
          </div>
          </Spin>
        </div>
      </div>
    );
  }
}

PlaceOrderComponent.propTypes = {};
PlaceOrderComponent.defaultProps = {};

const mapStateToProps = (state) => {
  const {
    products,
    orderHeader,
    itemError,
    createOrderHeaderPending,
    orderItem,
    priceBookEntry,
    itemSucc,
    fetchProductInfoError,
    fetchProductInfoPending,
    createOrderItemError,
    createOrderItemPending,
    priceBookEntryPending,
    invalidProducts,
    fetchOverrideChartReportPending,
    fetchOverrideChartDetailPending,
    setItemQuantityPending,
    createMuleOrderPending,
    muleOrder,
    createMuleOrderError,
    customerErrorStatus,
    supplierErrorStatus,
    overrideChartReport,
    fetchOverrideChartReportError,
    overrideChartDetail,
    fetchOverrideChartDetailError,
    asohValidationMsg,
    fetchAsohPending,
    fetchBulkAsohPending,
    fetchReferenceIdPending,
  } = state.order;
  const {
    auth,
    getOrderTokenPending,
    getAsohTokenPending,
  } = state.home;
  // const productsWithIndex = _.map(products, (product, index) => {
  //   return { ...product, index };
  // }
  // );
  const productsWithIndex = JSON.parse(JSON.stringify(products));
  return {
    products: productsWithIndex,
    orderHeader: orderHeader,
    itemError: itemError,
    createOrderHeaderPending: createOrderHeaderPending,
    orderItem,
    priceBookEntry,
    itemSucc: itemSucc,
    fetchProductInfoError: fetchProductInfoError,
    fetchProductInfoPending,
    createOrderItemError,
    createOrderItemPending,
    priceBookEntryPending,
    invalidProducts,
    fetchOverrideChartReportPending,
    fetchOverrideChartDetailPending,
    auth,
    setItemQuantityPending,
    createMuleOrderPending,
    muleOrder,
    createMuleOrderError,
    customerErrorStatus,
    supplierErrorStatus,
    overrideChartReport,
    fetchOverrideChartReportError,
    overrideChartDetail,
    fetchOverrideChartDetailError,
    asohValidationMsg,
    fetchAsohPending,
    fetchBulkAsohPending,
    fetchReferenceIdPending,
    getOrderTokenPending,
    getAsohTokenPending,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    clearSupplierInfo: () => {
      dispatch(clearSupplierInfo());
    },
    clearCustomerInfo: () => {
      dispatch(clearCustomerInfo());
    },
    clearDeliveryMethod: () => {
      dispatch(clearDeliveryMethod());
    },
    clearProductInfo: () => {
      dispatch(clearProductInfo());
    },
    fetchPriceBookEntry: (opco, productNumber) => {
      dispatch(fetchPriceBookEntry(opco, productNumber));
    },
    createOrderItem: (data) => {
      dispatch(createOrderItem(data));
    },
    createOrderHeader: (data) => {
      dispatch(createOrderHeader(data));
    },
    submitOrder: (data) => {
      dispatch(submitOrder(data));
    },
    createMuleOrder: (data) => {
      dispatch(createMuleOrder(data));
    },
    clearPickUpTime: () => {
      dispatch(clearPickUpTime());
    },
    getReferenceId: (data)=>{
      dispatch(getReferenceId(data));
    },
    fetchAsoh: (opco,supc,quantity,splitFlag,index,record)=>{
      dispatch(fetchASOH(opco,supc,quantity,splitFlag,index,record));
    },
    clearCreateOrderError:()=>{
      dispatch(clearCreateOrderError());
    },
    clearMuleOrder:()=>{
      dispatch(clearMuleOrder());
    },
  };
};

const PlaceOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(PlaceOrderComponent);

export default PlaceOrder;