import axios from 'axios';
import { API_REQUEST_URL } from '../../../common/api';
import {
  FETCH_CUSTOMER_INFO_BEGIN,
  FETCH_CUSTOMER_INFO_SUCCESS,
  FETCH_CUSTOMER_INFO_FAILURE,
  CLEAR_CUSTOMER_INFO
} from './constants';
import _ from 'lodash';
import { INVALID_CUSTOMER_ERROR_MSG, SHIP_TO_CUSTOMER_STATUS, SHIP_TO_CUSTOMER_STATUS_ERROR_MSG } from '../component/MessageConstants';

export function fetchCustomerInfo(opco, customerNumber) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_CUSTOMER_INFO_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/account/customer?opco=${opco}&customer=${customerNumber}`, {
        headers: {
          'Authorization': token,
          'cognitoToken': cognitoToken
        }
      });
      doRequest.then(
        (res) => {


          if (SHIP_TO_CUSTOMER_STATUS.indexOf(res.data.shipToCustomerStatus) > -1) {
            dispatch({
              type: FETCH_CUSTOMER_INFO_SUCCESS,
              data: res.data
            });
          } else {
            dispatch({
              type: FETCH_CUSTOMER_INFO_FAILURE,
              data: { error: SHIP_TO_CUSTOMER_STATUS_ERROR_MSG, errorStatus: 'error' },
            });
          }
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorCode = err && err.response?err.response.status:err;
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          if(errorCode === 401){
            dispatch({
              type: FETCH_CUSTOMER_INFO_FAILURE,
              data: { error: errorMsg,errorStatus: 'error' },
            });
          }else{
            dispatch({
              type: FETCH_CUSTOMER_INFO_FAILURE,
              data: { error: INVALID_CUSTOMER_ERROR_MSG,errorStatus: 'error' },
            });
          }
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function clearCustomerInfo() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_CUSTOMER_INFO,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_CUSTOMER_INFO_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        customer: {},
        fetchCustomerInfoPending: true,
        fetchCustomerInfoError: null
      };

    case FETCH_CUSTOMER_INFO_SUCCESS:
      // The request is success
      return {
        ...state,
        customer: action.data,
        fetchCustomerInfoPending: false,
        fetchCustomerInfoError: null,
        customerErrorStatus: 'success'
      };

    case FETCH_CUSTOMER_INFO_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchCustomerInfoPending: false,
        fetchCustomerInfoError: action.data.error,
        customerErrorStatus: action.data.errorStatus
      };

    case CLEAR_CUSTOMER_INFO:
      return {
        ...state,
        customer: {},
        fetchCustomerInfoError: null,
        customerErrorStatus: 'success'
      };

    default:
      return state;
  }
}
