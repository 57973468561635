import axios from 'axios';
import { API_REQUEST_URL } from '../../../common/api';
import {
    GET_REFERENCE_ID_BEGIN,
    GET_REFERENCE_ID_SUCCESS,
    GET_REFERENCE_ID_FAILURE,
} from './constants';
import _ from 'lodash';
import { createMuleOrder } from './createMuleOrder';

export function getReferenceId(requestData) {
    return (dispatch,getState) => { // optionally you can have getState as the second argument
        dispatch({
            type: GET_REFERENCE_ID_BEGIN,
        });
        const promise = new Promise((resolve, reject) => {
            const cognitoIdToken = getState().home.auth.cognitoIdToken;
            const cognitoToken = `Bearer ${cognitoIdToken}`;
            const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
            const doRequest = axios.get(`${API_REQUEST_URL}/order/referenceId`, {
                headers: {
                    'Authorization': token,
                    'cognitoToken': cognitoToken
                }
              });
            doRequest.then((res) => {
                if(res.data){
                    requestData['uomNumber'] = res.data;
                    dispatch(createMuleOrder(requestData));
                }
                dispatch({
                    type: GET_REFERENCE_ID_SUCCESS,
                    data: res.data,
                });
                resolve(res);
            },
                (err) => {
                    const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
                    dispatch({
                        type: GET_REFERENCE_ID_FAILURE,
                        data: { error: errorMsg },
                    });
                    reject(err);
                },
            );
        });
        return promise;
    };
}
export function reducer(state, action) {
    switch (action.type) {
        case GET_REFERENCE_ID_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                referenceId: null,
                fetchReferenceIdPending: true,
                fetchReferenceIdError: null,
            };

        case GET_REFERENCE_ID_SUCCESS:
            // The request is success
            return {
                ...state,
                referenceId: action.data,
                fetchReferenceIdPending: false,
                fetchReferenceIdError: null,
            };

        case GET_REFERENCE_ID_FAILURE:
            // The request is failed
            return {
                ...state,
                fetchReferenceIdPending: false,
                fetchReferenceIdError: action.data.error
            };
        default:
            return state;
    }
}
