import axios from 'axios';
import _ from 'lodash';
import { API_REQUEST_URL } from '../../../common/api';
import { INVALID_CHART_ERROR_MSG } from '../component/MessageConstants';
import {
    FETCH_OVERRIDE_CHART_REPORT_BEGIN,
    FETCH_OVERRIDE_CHART_REPORT_SUCCESS,
    FETCH_OVERRIDE_CHART_REPORT_FAILURE,
} from './constants';
import { getInvalidProduct } from './fetchProductInfo';
import { spliceInvalidProduct } from './sortObject';

export function fetchOverrideChartReport(args = {},productNumber,index) {
  return (dispatch,getState) => {
    dispatch({
      type: FETCH_OVERRIDE_CHART_REPORT_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;  //need change to OGM token
      const doRequest = axios.post(`${API_REQUEST_URL}/ogmOrder/overrideChartReport`,args,{
        headers: {
          'Authorization': token,
          'cognitoToken': cognitoToken
        }
        
      });
      let invalidProduct = [];
      doRequest.then(
        (res) => {
          const result = res.data.mapData.results[0];
          if (_.isEmpty(result.chartId)) {
            invalidProduct = getInvalidProduct(productNumber, index, INVALID_CHART_ERROR_MSG);
              dispatch({
                  type: FETCH_OVERRIDE_CHART_REPORT_FAILURE,
                  data: { error: res.data.errorMessage, index},
                  invalidProduct: invalidProduct
              });
              reject(res.data.errorMessage);
          } else {
              dispatch({
                  type: FETCH_OVERRIDE_CHART_REPORT_SUCCESS,
                  data: {...result,index},
                  invalidProduct: invalidProduct
              });
              resolve(result);
          }
        },
        (err) => {
          const errorMsg = err && err.response ? err.response.data ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.':'There is an error. Please refresh page or contact administrator.';
          invalidProduct = getInvalidProduct(productNumber, index, errorMsg);
          dispatch({
            type: FETCH_OVERRIDE_CHART_REPORT_FAILURE,
            data: { error: errorMsg, index },
            invalidProduct: invalidProduct
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_OVERRIDE_CHART_REPORT_BEGIN:
      return {
        ...state,
        fetchOverrideChartReportPending: true,
        fetchOverrideChartReportError: null,
      };

    case FETCH_OVERRIDE_CHART_REPORT_SUCCESS:
      let invalidSuccess = spliceInvalidProduct(state.invalidProducts,action);
      return {
        ...state,
        overrideChartReport: action.data,
        fetchOverrideChartReportPending: false,
        fetchOverrideChartReportError: null,
        invalidProducts: invalidSuccess
      };

    case FETCH_OVERRIDE_CHART_REPORT_FAILURE:
      let invalidFailure = spliceInvalidProduct(state.invalidProducts,action);
      return {
        ...state,
        fetchOverrideChartReportPending: false,
        fetchOverrideChartReportError: action.data.error,
        invalidProducts: invalidFailure
      };

    default:
      return state;
  }
}
