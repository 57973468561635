import React from 'react';
import { Form, Row, Col, Checkbox, Input, Select, DatePicker, Spin, message, Tooltip,Radio, Modal } from 'antd';
import { DATE_FROMAT_MM_DD_YYYY, DATE_FROMAT_MM_DD_YYYY_HH_MM_SS } from '../../common/constants';
// import { DATE_NEEDED_VALUE_IS_EMPTY, INVALID_WILL_CALL_TIME,DATE_NEEDED_VALUE_IS_CURRENT_TODAY } from './redux/constants';
import { fetchOpcos, fetchSupplierInfo, fetchCustomerInfo, updateDeliveryMethod, clearCustomerInfo, fetchWillCallTimes, clearPickUpTime, clearSupplierInfo, clearDeliveryMethod, clearProductInfo,resetAsohErrorMsg,resetAllItemQuantity,fetchBulkASOH,
  fetchIsDomSite, } from './redux/actions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import { DATE_NEEDED_VALUE_IS_CURRENT_TODAY, DATE_NEEDED_VALUE_IS_EMPTY, INVALID_WILL_CALL_TIME, INVALID_WILL_CALL_TIME_TODAY_AFTER_TWELVE_PM, INVALID_WILL_CALL_TIME_TODAY_NO_DATA, NETWORK_ERROR_MSG } from './component/MessageConstants';
import ErrorMessage from './component/ErrorMessage';
import { bulkAsohRequestBody, isTodayAndAfterNoon, isTodayAndNotAfterNoon, isTodayOrNextDay } from './componentUtils';

const colSpan = 8;
const { TextArea } = Input;

class OrderHeaderComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //opcoId: '',
      todayDate: moment().format(DATE_FROMAT_MM_DD_YYYY),
      willCallThreshold: moment(moment().format(DATE_FROMAT_MM_DD_YYYY) + ' 12:00:00', DATE_FROMAT_MM_DD_YYYY_HH_MM_SS),
      title:''
    };

    // this.onDeliveryMethodChanged = this.onDeliveryMethodChanged.bind(this);
    this.fetchSupplierInfo = this.fetchSupplierInfo.bind(this);
    // this.fetchCustomerInfo = this.fetchCustomerInfo.bind(this);
    this.paddingZero = this.paddingZero.bind(this);
    this.disabledDate = this.disabledDate.bind(this);
    // this.handleCheckbox = this.handleCheckbox.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const { fetchCustomerInfoError,fetchSupplierInfoError,supplierErrorStatus,customerErrorStatus} = nextProps;
    const {formError,form} = this.props;
    const customerValue = form.current.getFieldValue("customerNumber");
    if(!_.isEmpty(customerValue) && customerValue !== undefined) {
      if (customerErrorStatus === 'error') {
        form.current.setFields([
          {
            name:"customerNumber",
            errors:[fetchCustomerInfoError]
          }
        ]);
        this.setState({
          ...this.state,
          formError: fetchCustomerInfoError
        })
      }else{
        form.current.setFields([
          {
            name:"customerNumber",
            errors:null
          }
        ]);
        this.setState({
          ...this.state,
          formError: null
        });
      }
    }
    const supplierValue = form.current.getFieldValue("supplierAccount");
    if(!_.isEmpty(supplierValue) && supplierValue !== undefined) {
      if(supplierErrorStatus === 'error' && (!_.isEmpty(fetchSupplierInfoError) && fetchSupplierInfoError.indexOf(NETWORK_ERROR_MSG) !== -1)
      &&  this.props.fetchSupplierInfoError !== fetchSupplierInfoError){
        //show popup
        this.errorPopupModal('Network Issue',fetchSupplierInfoError);
        form.current.setFields([
          {
            name:"supplierAccount",
            errors:[fetchSupplierInfoError]
          }
        ]);
        this.setState({
          ...this.state,
          formError: fetchSupplierInfoError
        });
      } else if (supplierErrorStatus === 'error' && this.props.fetchSupplierInfoError !== fetchSupplierInfoError) {
       form.current.setFields([
          {
            name:"supplierAccount",
            errors:[fetchSupplierInfoError]
          }
        ]);
        this.setState({
          ...this.state,
          formError: fetchSupplierInfoError
        });
      } else{
        form.current.setFields([
          {
            name:"supplierAccount",
            errors:null
          }
        ]);
        this.setState({
          ...this.state,
          formError: null
        });
      }
    }
  }
  errorPopupModal = (title,message) => {
    const {clearSupplierInfo} = this.props;
    let modal = Modal.error({
      centered: true,
      closable: true,
      visible: this.state.visible,
      title: title,
      content: (
        <div style={{ marginTop: 20 }}>
          <p>{message}</p>
        </div>
      ),
      okText: 'Ok',
      okType: 'green',
      onOk: () => {
        this.setState({
          visible: false,
        });
      },
      onCancel:()=>{
        this.setState({
          visible: false,
        });
      }
    });
    return modal;
  }
  // onDeliveryMethodChanged(event, fieldName) {
  //   this.props.updateDeliveryMethod(fieldName, event.target.checked);
  //   if (!event.target.checked && fieldName === 'willCall') {
  //     this.props.handleChange('dateNeeded', null);
  //     this.props.handleChange('pickUpTime', null);
  //   }
  // }

  fetchSupplierInfo(event) {
    const {fetchSupplierInfo,form} = this.props;
    const opcoId = form.current.getFieldValue('site');
    if (_.isEmpty(opcoId)) {
      message.error('Please select Site!');
    } else if (_.isEmpty(event.target.value)) {
      message.error('Please input Supplier!');
    } else {
      fetchSupplierInfo(opcoId, event.target.value.replace(/\s+/g,'').trim());
    }
  }
  handleSupplierChange =(value)=>{
    const {onClearForm,resetAsohErrorMsg} = this.props;
    onClearForm('supplierAccount');
    //if change supplier account will clear form, so need to clear asoh errorMessage
    resetAsohErrorMsg();
  }
  handleCustomerInfo(event) {
    const {clearCustomerInfo,form,fetchCustomerInfo} = this.props;
    const opcoId = form.current.getFieldValue('site');
    if (_.isEmpty(opcoId)) {
      message.error('Please select Site!');
    } else if (_.isEmpty(event.target.value)) {
      clearCustomerInfo();
      message.error('Please input Customer!');
    } else {
      fetchCustomerInfo(opcoId, event.target.value.replace(/\s+/g,'').trim());
    }
  }

  paddingZero(number) {
    let str = String(1000 + number);
    return str.substr(1);

  }

  disabledDate(current) {
    return current && current <= moment(moment().format(DATE_FROMAT_MM_DD_YYYY), DATE_FROMAT_MM_DD_YYYY) || moment(current).weekday() === 0;
  }
  validatePickupTime = (rule, value) => {
    const { formatPickupTime,willCallTimes } = this.props;
    let newValue = formatPickupTime('pickupTime',value);
    const dateNeeded = this.props.form.current.getFieldValue('dateNeeded');
    let selectedTime = moment(moment(dateNeeded).format(DATE_FROMAT_MM_DD_YYYY) + newValue,DATE_FROMAT_MM_DD_YYYY_HH_MM_SS);
    if (dateNeeded === null || dateNeeded === undefined) {
      if (value === null || value === undefined) {
        return Promise.resolve();
      } else {
        return Promise.reject(new Error(DATE_NEEDED_VALUE_IS_EMPTY));
      }
    // } else if ((dateNeeded !== null && dateNeeded !== undefined) && (value !== null && value !== undefined) && value.isSame(dateNeeded, 'day') && value.isAfter(this.state.willCallThreshold)) {
    } else if ((dateNeeded !== null && dateNeeded !== undefined) && (value !== null && value !== undefined) && moment(dateNeeded).isSame(this.state.todayDate,'day') && selectedTime.isAfter(this.state.willCallThreshold)){
      // return Promise.reject(new Error(INVALID_WILL_CALL_TIME));
      return Promise.resolve();
    } else if(isTodayAndNotAfterNoon(dateNeeded,this.state.willCallThreshold) && _.isEmpty(willCallTimes)){
      //if dateNeeded is today and current time is before 12pm,but do not have available time slot. as pick up time must be after current time than 2 hours and a half hours 
      return Promise.reject(new Error(INVALID_WILL_CALL_TIME_TODAY_NO_DATA));
    }else {
      return Promise.resolve();
    }
  };
  handleDateNeeded = (field, value) => {
    const { fetchWillCallTimes,form,clearPickUpTime,products,resetAsohErrorMsg,fetchBulkASOH } = this.props;
    form.current.setFieldsValue({'pickUpTime': null});
    clearPickUpTime();
    // this.props.handleChange(field, value);
    if(!_.isEmpty(value)){
      const checkedValues =  form.current.getFieldValue('deliveryMethod');
      if(checkedValues !== undefined && checkedValues === 'willCall'){
        const opcoId = form.current.getFieldValue('site');
        if(opcoId === undefined){
          message.error('Please select Site!');
        }else{
          fetchWillCallTimes(opcoId,value);
        }
      }
    }
    //if change dateNeeded to today or next day will recall asoh api
    if(isTodayOrNextDay(value)){
      if(!_.isEmpty(bulkAsohRequestBody(products).products)){
        resetAsohErrorMsg();
        fetchBulkASOH(bulkAsohRequestBody(products));
      }
    }else{
      resetAsohErrorMsg();
    }
  }
  handleRadioChange = (e) => {
    const { fetchWillCallTimes,form,clearCustomerInfo } = this.props;
    const checkedValues = e.target.value;
    // if (checkedValues.indexOf('willCall') === -1) {
    //   form.current.setFieldsValue({ 'pickUpTime': null });
    // }
    if (checkedValues === 'routetoCustomer') {
      form.current.setFieldsValue({ 'pickUpTime': null });
    }
    if(checkedValues === 'willCall'){
      form.current.setFieldsValue({ 'customerNumber': null });
      clearCustomerInfo();
      const opcoId = form.current.getFieldValue('site');
      const dateNeeded = form.current.getFieldValue('dateNeeded');
      if(dateNeeded === undefined || opcoId === undefined){
        message.error('Please select Site and Date Needed.')
        return;
      }else{
        fetchWillCallTimes(opcoId,dateNeeded);
      }
    }
  }
  handleSite =(opcoId,event) =>{
    const { onClearForm,resetAsohErrorMsg,fetchIsDomSite } = this.props;
    onClearForm('site');
    const tipTitle = event.children.join(''); 
    this.siteInputLimit(tipTitle);
    //if change site will clear form, so need to clear asoh errorMessage
    resetAsohErrorMsg();
    fetchIsDomSite(opcoId);
  }
  siteInputLimit = (value) =>{
    if(value.length > 35){
      this.setState({
        ...this.state,
        title: value
      })
    }else{
      this.setState({
        ...this.state,
        title: ''
      })
    }
  }
  validateDateNeededWithRouteToCustomer =(rule,value)=>{
    const { getFieldValue,setFieldsValue } = this.props.form.current;
    const { handleChange,clearPickUpTime} = this.props;
    if(getFieldValue('deliveryMethod') !== undefined && getFieldValue('deliveryMethod') === 'routetoCustomer'){
      //reset afterThreshold status if select routetoCustomer,otherwise submit button is disabled
      handleChange('afterThreshold',false);
      if(value !== undefined && moment(value).isSame(this.state.todayDate,'day')){
        //reset afterThreshold status if select routetoCustomer,otherwise submit button is disabled
        handleChange('afterThreshold', false);
        return Promise.reject(new Error(DATE_NEEDED_VALUE_IS_CURRENT_TODAY));
      }else{
        return Promise.resolve();
      }
    }else if(getFieldValue('deliveryMethod') !== undefined && getFieldValue('deliveryMethod') === 'willCall'){
      const dateNeeded = getFieldValue('dateNeeded');
      if(isTodayAndAfterNoon(dateNeeded,this.state.willCallThreshold)){
        handleChange('afterThreshold',true);
        setFieldsValue({'pickUpTime': []});
        clearPickUpTime();
        return Promise.reject(new Error(INVALID_WILL_CALL_TIME_TODAY_AFTER_TWELVE_PM));
      }else{
        handleChange('afterThreshold',false);
        return Promise.resolve();
      }
    }else{
      return Promise.resolve()
    }
  }
  onCustomerKeyDown = (e) => {
    //backspace keyCode = 8,tab's keyCode = 8,v's keycode = 86
    //enable ctrl+v
    if(e.ctrlKey){
      if(!(/[\d]/.test(e.key)) && e.keyCode !== 86){
        e.preventDefault();
      }
    }else{
      if(!(/[\d]/.test(e.key)) && e.keyCode !== 8 && e.keyCode !== 9 ) {
        e.preventDefault();
      }
    }
  }
  validateCustomer = (rule,value)=>{
    var n = Number(value);
    if(!value){
      return Promise.reject(new Error("Please input Customer Number"));
    }else if(isNaN(n)){
      return Promise.reject(new Error("Customer Number should be Only number!"));
    }else{
      return Promise.resolve();
    }
  }
  render() {
    const {
      opcos,
      fetchOpcosInfoPending,
      supplier,
      fetchSupplierInfoPending,
      fetchSupplierInfoError,
      supplierErrorStatus,
      customer,
      fetchCustomerInfoPending,
      fetchCustomerInfoError,
      customerErrorStatus,
      willCall,
      // routeToCustomer,
      willCallTimes,
      fetchWillCallTimesPending,
      form,
      sites,
      fetchSiteInfoPending
    } = this.props;
    return (
      <>
        <Row span={24} className="order-params">
          <Col span={10}>
      
              <Tooltip title={this.state.title.length === 0 ? '' : this.state.title}>
                <Form.Item name="site" label="Site" labelCol={{ span: colSpan, offset: 0 }} 
                  rules={[{ required: true, message: 'Please select site', }]}>
                  <Select
                    showSearch
                    // allowClear
                    // dropdownMatchSelectWidth={false}
                    maxTagCount={1}
                    maxTagTextLength={20}
                    loading={fetchOpcosInfoPending}
                    onChange={(opcoId,event) => this.handleSite(opcoId,event)}
                    style={{ width: 280 }}
                  >
                  {!_.isEmpty(opcos) && opcos.rows.map(item => {
                      // let opcoId = this.paddingZero(item.id);
                      return (
                        <Select.Option key={item.opcoNo} label={item.opcoNo}>
                          {item.opcoNo} - {item.opcoName}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Tooltip> 
          </Col>
          <Col span={10}>
            <Form.Item
              // name="today" 
              label="Today’s Date"
              labelCol={{ span: colSpan, offset: 0 }}>
              {this.state.todayDate}
            </Form.Item>
          </Col>
        </Row>
       
        <Row span={24} className="order-params">
          <Col span={10}>
            <Form.Item
              name="supplierAccount"
              label="Broker/Supplier Account"
              labelCol={{ span: colSpan, offset: 0 }}
              rules={[{ required: true, message: 'Please input Supplier Account' }]}
              // help={fetchSupplierInfoError}
              // validateStatus={supplierErrorStatus}
              getValueFromEvent={event=>{
                return event.target.value.replace(/\s+/g,'').trim()
              }}
            >
              <Input onBlur={(event) => this.fetchSupplierInfo(event)} 
                onChange={(event) => this.handleSupplierChange(event.target.value)} 
                />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              // name="supplierName"
              label="Broker/Supplier Name"
              labelCol={{ span: colSpan, offset: 0 }}
            >
              {fetchSupplierInfoPending ? <Spin /> : supplier.name}
            </Form.Item>
          </Col>
        </Row>
        <Row span={24} className="order-params">
          <Col span={10}>
            <Form.Item
              name="contactName"
              label="Contact Name"
              labelCol={{ span: colSpan, offset: 0 }}
            >
              <Input maxLength = {75}/>
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="contactPhone"
              label="Contact Phone"
              labelCol={{ span: colSpan, offset: 0 }}
            >
              <Input maxLength = {75}/>
            </Form.Item>
          </Col>
        </Row>
        <Row span={24} className="order-params">
          <Col span={10}>
            <Form.Item name="dateNeeded" label="Date Needed" labelCol={{ span: colSpan, offset: 0 }}
              rules={[{ required: true, message: 'Please select Date Needed' },
                {
                  validator: this.validateDateNeededWithRouteToCustomer
                }
              ]}
              dependencies={['deliveryMethod']}
            >
              <DatePicker disabledDate={this.disabledDate} onChange={time => this.handleDateNeeded('dateNeeded', time)} 
                              onClear={() => form.setFieldsValue({'pickUpTime': []})}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => prevValues.deliveryMethod !== currentValues.deliveryMethod}
            >
              {({ getFieldValue }) => {
                return getFieldValue('deliveryMethod') !== undefined && getFieldValue('deliveryMethod') === 'willCall' ? (
                  <Form.Item
                    name="pickUpTime"
                    label="Pick Up Time"
                    labelCol={{ span: colSpan, offset: 0 }}
                    rules={[{ required: isTodayAndAfterNoon(getFieldValue('dateNeeded'),this.state.willCallThreshold)?false:true, message: 'Please select Pick Up Time' },
                    {
                      validator: this.validatePickupTime
                    }
                    ]}
                    dependencies={['dateNeeded']}
                  >
                    {/* <DatePicker showTime disabledDate={this.disabledDate} onChange={time => this.props.handleChange('pickUpTime', time)} /> */}
                    <Select
                      // showSearch
                      // allowClear
                      // maxTagCount={1}
                      // maxTagTextLength={20}
                      loading={fetchWillCallTimesPending}
                      //onChange={(pickUpTime) =>this.props.handleChange('pickUpTime', pickUpTime)}
                      style={{ width: 280 }}
              >
                {willCallTimes !== null && willCallTimes !== undefined && willCallTimes.map(item => {
                    return (
                    <Select.Option key={item.routeName +`-` + item.pickupTime} label={item.routeName}>
                      {item.pickupTime}
                    </Select.Option>
                  );
                })}
              </Select>
                  </Form.Item>
                ) : null
              }}
            </Form.Item>
          </Col>
        </Row>
        {/* <Row span={24} className="order-params">
          <Col span={10}>
            <Form.Item
              name="approver"
              label="Approver"
              labelCol={{ span: colSpan, offset: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item
              name="approvalPONumber"
              label="Approval#/PO#"
              labelCol={{ span: colSpan, offset: 0 }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row> */}
        <Row span={24} className="order-params">
          <Col span={10}>
            <Form.Item
              name="deliveryMethod"
              label="Delivery Method"
              labelCol={{ span: colSpan, offset: 0 }}
              rules={[{ required: true, message: 'Please select Delivery Method', }]}
            >
              <Radio.Group onChange={this.handleRadioChange}>
                <Radio value="willCall">Will Call</Radio>
                <Radio value="routetoCustomer">Route to Customer</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) => prevValues.deliveryMethod !== currentValues.deliveryMethod}
          >
            {({ getFieldValue }) => {
              return getFieldValue('deliveryMethod') !== undefined && getFieldValue('deliveryMethod') === 'routetoCustomer' ? (
                <Row span={24} className="order-params">
                  <Col span={10}>
                    <Form.Item
                      name="customerNumber"
                      label="Customer #"
                      labelCol={{ span: colSpan, offset: 0 }}
                      rules={[{ required: true, message: '' },{
                        validator: this.validateCustomer
                      }]}
                      getValueFromEvent={event=>{
                        return event.target.value.replace(/\s+/g,'').trim();
                      }}
                    >
                      <Input onBlur={(event) => this.handleCustomerInfo(event)} 
                        maxLength={15}
                        onKeyDown={e => this.onCustomerKeyDown(e)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <Form.Item
                      label="Customer Name"
                      labelCol={{ span: colSpan, offset: 0 }}
                    >
                      {fetchCustomerInfoPending ? <Spin /> : !_.isEmpty(customer)?customer.name:''}
                    </Form.Item>
                  </Col>
                </Row>
              ) : null
            }}
          </Form.Item>
        </>
        <Row span={24} className="order-params">
          <Col span={10}>
            <Form.Item
              name="note"
              label="Note"
              labelCol={{ span: colSpan, offset: 0 }}
            >
              <TextArea rows={3} maxLength={120}/>
            </Form.Item>
          </Col>
        </Row>
      </>
    );
  }
}

OrderHeaderComponent.propTypes = {
  opcos: PropTypes.object,
  fetchOpcosInfoPending: PropTypes.bool,
  supplier: PropTypes.object,
  fetchSupplierInfoPending: PropTypes.bool,
  fetchSupplierInfoError: PropTypes.string,
  supplierErrorStatus: PropTypes.string,
  customer: PropTypes.object,
  fetchCustomerInfoPending: PropTypes.bool,
  fetchCustomerInfoError: PropTypes.string,
  customerErrorStatus: PropTypes.string,
  willCall: PropTypes.bool,
  // routeToCustomer: PropTypes.bool
};

OrderHeaderComponent.defaultProps = {};

const mapStateToProps = state => ({
  opcos: state.order.opcos,
  fetchOpcosInfoPending: state.order.fetchOpcosInfoPending,
  supplier: state.order.supplier,
  fetchSupplierInfoPending: state.order.fetchSupplierInfoPending,
  fetchSupplierInfoError: state.order.fetchSupplierInfoError,
  supplierErrorStatus: state.order.supplierErrorStatus,
  customer: state.order.customer,
  fetchCustomerInfoPending: state.order.fetchCustomerInfoPending,
  fetchCustomerInfoError: state.order.fetchCustomerInfoError,
  customerErrorStatus: state.order.customerErrorStatus,
  willCall: state.order.willCall,
  // routeToCustomer: state.order.routeToCustomer,
  willCallTimes: state.order.willCallTimes,
  fetchWillCallTimesPending: state.order.fetchWillCallTimesPending,
  sites: state.order.sites,
  fetchSiteInfoPending: state.order.fetchSiteInfoPending,
  products: state.order.products
});

const mapDispatchToProps = dispatch => {
  return {
    fetchOpcos: () => {
      dispatch(fetchOpcos());
    },
    fetchSupplierInfo: (opcoId, supplierId) => {
      dispatch(fetchSupplierInfo(opcoId, supplierId));
    },
    fetchCustomerInfo: (opcoId, customerId) => {
      dispatch(fetchCustomerInfo(opcoId, customerId));
    },
    updateDeliveryMethod: (fieldName, value) => {
      dispatch(updateDeliveryMethod(fieldName, value));
    },
    clearCustomerInfo: () => {
      dispatch(clearCustomerInfo());
    },
    fetchWillCallTimes: (opcoId,dateNeeded) => {
      dispatch(fetchWillCallTimes(opcoId,dateNeeded));
    },
    clearPickUpTime: () => {
      dispatch(clearPickUpTime());
    },
    clearSupplierInfo: () => {
      dispatch(clearSupplierInfo());
    },
    clearCustomerInfo: () => {
      dispatch(clearCustomerInfo());
    },
    clearDeliveryMethod: () => {
      dispatch(clearDeliveryMethod());
    },
    clearProductInfo: () => {
      dispatch(clearProductInfo());
    },
    resetAsohErrorMsg: () => {
      dispatch(resetAsohErrorMsg());
    },
    resetAllItemQuantity: () => {
      dispatch(resetAllItemQuantity());
    },
    fetchBulkASOH: (args) => {
      dispatch(fetchBulkASOH(args));
    },
    fetchIsDomSite: (args) => {
      dispatch(fetchIsDomSite(args));
    },
  };
};

const OrderHeader = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderHeaderComponent);


export default OrderHeader;