import axios from 'axios';
import { API_REQUEST_URL } from '../../../common/api';
import _ from 'lodash';
import {
  PLACE_ORDER_FETCH_OPCO_DATA_BEGIN,
  PLACE_ORDER_FETCH_OPCO_DATA_SUCCESS,
  PLACE_ORDER_FETCH_OPCO_DATA_FAILURE,
  // CLEAR_OPCO_INFO
} from './constants';
import { USELESS_OPCO_LIST } from '../../../common/constants';

export function fetchOpcos(args = {}) {
  return (dispatch,getState) => {
    dispatch({
      type: PLACE_ORDER_FETCH_OPCO_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/susReferp/opcos`,{
        headers: {
          'Authorization': token,
          'cognitoToken': cognitoToken
        }
      });
      doRequest.then(
        (res) => {
          let data = res.data;
          // if(!_.isEmpty(data)) {
          //   data.rows.push({
          //     "opcoNo": "101",
          //     "opcoName": "Test"
          //   })
            data.rows = data.rows.filter(item => 
              {
                const result = USELESS_OPCO_LIST.find(({opcoNo, opcoName}) => opcoNo === item.opcoNo);
                return _.isEmpty(result);
              });
          // }
          dispatch({
            type: PLACE_ORDER_FETCH_OPCO_DATA_SUCCESS,
            data: res.data,
          });
          resolve(res);
        },
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: PLACE_ORDER_FETCH_OPCO_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}
// export function clearOpcoInfo() {
//   return (dispatch) => {
//     dispatch({
//       type: CLEAR_OPCO_INFO,
//     });
//   };
// }
export function reducer(state, action) {
  switch (action.type) {
    case PLACE_ORDER_FETCH_OPCO_DATA_BEGIN:
      return {
        ...state,
        fetchOpcosInfoPending: true,
        fetchOpcosInfoError: null,
      };

    case PLACE_ORDER_FETCH_OPCO_DATA_SUCCESS:
      return {
        ...state,
        opcos: action.data,
        fetchOpcosInfoPending: false,
        fetchOpcosInfoError: null,
      };

    case PLACE_ORDER_FETCH_OPCO_DATA_FAILURE:
      return {
        ...state,
        fetchOpcosInfoPending: false,
        fetchOpcosInfoError: action.data.error,
      };
      // case CLEAR_OPCO_INFO:
      //   return {
      //     ...state,
      //     opcos: {},
      //     fetchCustomerInfoError: null
      //   };
    default:
      return state;
  }
}
