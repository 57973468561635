import axios from 'axios';
import { useDispatch } from 'react-redux';
import { API_REQUEST_URL } from '../../../common/api';
import {
  GET_ORDER_TOKEN_BEGIN,
  GET_ORDER_TOKEN_SUCCESS,
  GET_ORDER_TOKEN_FAILURE,
  GET_ACCOUNT_TOKEN_BEGIN,
  GET_ACCOUNT_TOKEN_SUCCESS,
  GET_ACCOUNT_TOKEN_FAILURE,
  GET_USBL_OGM_TOKEN_BEGIN,
  GET_USBL_OGM_TOKEN_SUCCESS,
  GET_USBL_OGM_TOKEN_FAILURE,
  GET_SALESFORCE_TOKEN_BEGIN,
  GET_SALESFORCE_TOKEN_SUCCESS,
  GET_SALESFORCE_TOKEN_FAILURE,
  GET_WILLCALLTIMES_TOKEN_BEGIN,
  GET_WILLCALLTIMES_TOKEN_SUCCESS,
  GET_WILLCALLTIMES_TOKEN_FAILURE,
  GET_ORDERREFERENCE_TOKEN_BEGIN,
  GET_ORDERREFERENCE_TOKEN_SUCCESS,
  GET_ORDERREFERENCE_TOKEN_FAILURE,
  GET_ASOH_TOKEN_BEGIN,
  GET_ASOH_TOKEN_SUCCESS,
  GET_ASOH_TOKEN_FAILURE,
} from './constants';
import _ from 'lodash';
import { fetchOpcos, fetchSiteData } from '../../order/redux/actions';

export function getOrderToken() {

  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: GET_ORDER_TOKEN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/order/token`,{
        headers: {
          'cognitoToken': cognitoToken
        }
      });
      doRequest.then(
        (res) => {
          window.sessionStorage.setItem('qaToken', res.data.access_token);
          dispatch({
            type: GET_ORDER_TOKEN_SUCCESS,
            data: {
              accessToken: res.data.access_token,
              expiresIn: 123
            },
          });
          // dispatch(fetchSiteData());
          dispatch(fetchOpcos());
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: GET_ORDER_TOKEN_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function getAccountToken() {

  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: GET_ACCOUNT_TOKEN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/account/token`,{
        headers: {
          'cognitoToken': cognitoToken
        }
      });
      doRequest.then(
        (res) => {
          window.sessionStorage.setItem('devToken', res.data.access_token);
          dispatch({
            type: GET_ACCOUNT_TOKEN_SUCCESS,
            data: {
              accessToken: res.data.access_token,
              expiresIn: 123
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: GET_ACCOUNT_TOKEN_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function getUSBLOgmToken() {

  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: GET_USBL_OGM_TOKEN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/ogmOrder/token`,{
        headers: {
          'cognitoToken': cognitoToken
        }
      });
      doRequest.then(
        (res) => {
          window.sessionStorage.setItem('USBL_OGM_TOKEN', res.data.access_token);
          dispatch({
            type: GET_USBL_OGM_TOKEN_SUCCESS,
            data: {
              accessToken: res.data.access_token,
              expiresIn: 123
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: GET_USBL_OGM_TOKEN_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function getSalesforceOrderToken() {

  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: GET_SALESFORCE_TOKEN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/salesforceOrder/token`,{
        headers: {
          'cognitoToken': cognitoToken
        }
      });
      doRequest.then(
        (res) => {
          window.sessionStorage.setItem('salesForceToken', res.data.access_token);
          dispatch({
            type: GET_SALESFORCE_TOKEN_SUCCESS,
            data: {
              accessToken: res.data.access_token,
              expiresIn: 123
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: GET_SALESFORCE_TOKEN_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function getWillCallTimesToken() {

  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: GET_WILLCALLTIMES_TOKEN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/susReferp/token`,{
        headers: {
          'cognitoToken': cognitoToken
        }
      });
      doRequest.then(
        (res) => {
          window.sessionStorage.setItem('willCallTimesToken', res.data.access_token);
          dispatch({
            type: GET_WILLCALLTIMES_TOKEN_SUCCESS,
            data: {
              accessToken: res.data.access_token,
              expiresIn: 123
            },
          });
          dispatch(fetchOpcos());
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: GET_WILLCALLTIMES_TOKEN_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}
export function getOrderReferenceToken() {

  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: GET_ORDERREFERENCE_TOKEN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/order/orderReferenceToken`,{
        headers: {
          'cognitoToken': cognitoToken
        }
      });
      doRequest.then(
        (res) => {
          window.sessionStorage.setItem('orderReferenceToken', res.data.access_token);
          dispatch({
            type: GET_ORDERREFERENCE_TOKEN_SUCCESS,
            data: {
              accessToken: res.data.access_token,
              expiresIn: 123
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: GET_ORDERREFERENCE_TOKEN_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function getAsohToken() {

  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: GET_ASOH_TOKEN_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/order/asohToken`,{
        headers: {
          'cognitoToken': cognitoToken
        }
      });
      doRequest.then(
        (res) => {
          window.sessionStorage.setItem('asohToken', res.data.access_token);
          dispatch({
            type: GET_ASOH_TOKEN_SUCCESS,
            data: {
              accessToken: res.data.access_token,
              expiresIn: 123
            },
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: GET_ASOH_TOKEN_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}
export function useGetToken() {
    const dispatch = useDispatch();
    const callOrderTokenAction = useEffect(() => { dispatch(getOrderToken()) }, []); // eslint-disable-line
    const callAccountTokenAction = useEffect(() => { dispatch(getAccountToken()) }, []); // eslint-disable-line
    return {
      fetchOrderToken: callOrderTokenAction,
      fetchAccountToken: callAccountTokenAction
    };
  }
export function reducer(state, action) {
  switch (action.type) {
    case GET_ORDER_TOKEN_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        orderToken: {},
        getOrderTokenPending: true,
        getOrderTokenError: null,
      };

    case GET_ORDER_TOKEN_SUCCESS:
      // The request is success
      return {
        ...state,
        orderToken: action.data,
        getOrderTokenPending: false,
        getOrderTokenError: null,
      };

    case GET_ORDER_TOKEN_FAILURE:
      // The request is failed
      return {
        ...state,
        orderToken: {},
        getOrderTokenPending: false,
        getOrderTokenError: action.data.error,
      };

    case GET_ACCOUNT_TOKEN_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        accountToken: {},
        getAccountTokenPending: true,
        getAccountTokenError: null,
      };

    case GET_ACCOUNT_TOKEN_SUCCESS:
      // The request is success
      return {
        ...state,
        accountToken: action.data,
        getAccountTokenPending: false,
        getAccountTokenError: null,
      };

    case GET_ACCOUNT_TOKEN_FAILURE:
      // The request is failed
      return {
        ...state,
        accountToken: {},
        getAccountTokenPending: false,
        getAccountTokenError: action.data.error,
      };

      case GET_USBL_OGM_TOKEN_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        usblOgmToken: {},
        getUSBLOgmTokenPending: true,
        getUSBLOgmTokenError: null,
      };

    case GET_USBL_OGM_TOKEN_SUCCESS:
      // The request is success
      return {
        ...state,
        usblOgmToken: action.data,
        getUSBLOgmTokenPending: false,
        getUSBLOgmTokenError: null,
      };

    case GET_USBL_OGM_TOKEN_FAILURE:
      // The request is failed
      return {
        ...state,
        usblOgmToken: {},
        getUSBLOgmTokenPending: false,
        getUSBLOgmTokenError: action.data.error,
      };
      case GET_SALESFORCE_TOKEN_BEGIN:
        // Just after a request is sent
        return {
          ...state,
          salesforceToken: {},
          getSalesforceTokenPending: true,
          getSalesforceTokenError: null,
        };
  
      case GET_SALESFORCE_TOKEN_SUCCESS:
        // The request is success
        return {
          ...state,
          salesforceToken: action.data,
          getSalesforceTokenPending: false,
          getSalesforceTokenError: null,
        };
  
      case GET_SALESFORCE_TOKEN_FAILURE:
        // The request is failed
        return {
          ...state,
          salesforceToken: {},
          getSalesforceTokenPending: false,
          getSalesforceTokenError: action.data.error,
        };

        case GET_WILLCALLTIMES_TOKEN_BEGIN:
          // Just after a request is sent
          return {
            ...state,
            willCallTimesToken: {},
            getWillCallTimesTokenPending: true,
            getWillCallTimesTokenError: null,
          };
    
        case GET_WILLCALLTIMES_TOKEN_SUCCESS:
          // The request is success
          return {
            ...state,
            willCallTimesToken: action.data,
            getWillCallTimesTokenPending: false,
            getWillCallTimesTokenError: null,
          };
    
        case GET_WILLCALLTIMES_TOKEN_FAILURE:
          // The request is failed
          return {
            ...state,
            willCallTimesToken: {},
            getWillCallTimesTokenPending: false,
            getWillCallTimesTokenError: action.data.error,
          };
          case GET_ORDERREFERENCE_TOKEN_BEGIN:
            // Just after a request is sent
            return {
              ...state,
              orderReferenceToken: {},
              getOrderReferenceTokenPending: true,
              getOrderReferenceTokenError: null,
            };
      
          case GET_ORDERREFERENCE_TOKEN_SUCCESS:
            // The request is success
            return {
              ...state,
              orderReferenceToken: action.data,
              getOrderReferenceTokenPending: false,
              getOrderReferenceTokenError: null,
            };
      
          case GET_ORDERREFERENCE_TOKEN_FAILURE:
            // The request is failed
            return {
              ...state,
              orderReferenceToken: {},
              getOrderReferenceTokenPending: false,
              getOrderReferenceTokenError: action.data.error,
            };
          
            case GET_ASOH_TOKEN_BEGIN:
              // Just after a request is sent
              return {
                ...state,
                asohToken: {},
                getAsohTokenPending: true,
                getAsohTokenError: null,
              };
        
            case GET_ASOH_TOKEN_SUCCESS:
              // The request is success
              return {
                ...state,
                asohToken: action.data,
                getAsohTokenPending: false,
                getAsohTokenError: null,
              };
        
            case GET_ASOH_TOKEN_FAILURE:
              // The request is failed
              return {
                ...state,
                asohToken: {},
                getAsohTokenPending: false,
                getAsohTokenError: action.data.error,
              };
    default:
      return state;
  }
}
