import axios from 'axios';
import { WS_REQUEST_SITES_URL } from '../../../common/api';
import {
  FETCH_SITE_DATA_BEGIN,
  FETCH_SITE_DATA_SUCCESS,
  FETCH_SITE_DATA_FAILURE,
} from './constants';
import { sortFiltersListByKey } from './sortObject';

export function fetchSiteData() {
  return (dispatch, getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_SITE_DATA_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      //const cognitoIdToken = getState().home.auth.cognitoIdToken;
    //   const token = `Bearer ${cognitoIdToken}`;
      const doRequest = axios.get(`${WS_REQUEST_SITES_URL}/siteMappings/getAll`, {});
      doRequest.then(
        (res) => {
          let sortedOrgData = res.data;
          let initOrgList = {
            market: [],
            region: [],
            site: [],
          };

          sortedOrgData = sortFiltersListByKey(sortedOrgData, 'market');
          initOrgList = sortedOrgData.map(marketItem => {
            // initOrgList.market.push(marketItem.market);
            marketItem.regions.map(regionItem => {
              regionItem.sites.map(siteItem => {
                return initOrgList.site.push({site_nbr: siteItem.site_nbr, site_desc: siteItem.site_desc});
              });
              return initOrgList.region.push(regionItem.region);
            });
            return initOrgList;
          });
        //   initOrgList[0].region = sortFiltersListByKey(initOrgList[0].region);
          initOrgList[0].site = sortFiltersListByKey(initOrgList[0].site, 'site_nbr');

          dispatch({
            type: FETCH_SITE_DATA_SUCCESS,
            data: initOrgList[0].site,
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
          dispatch({
            type: FETCH_SITE_DATA_FAILURE,
            data: { error: errorMsg },
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_SITE_DATA_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        fetchSiteInfoPending: true,
        fetchSiteInfoError: null,
      };

    case FETCH_SITE_DATA_SUCCESS:
      // The request is success
      return {
        ...state,
        sites: action.data,
        fetchSiteInfoPending: false,
        fetchSiteInfoError: null,
      };

    case FETCH_SITE_DATA_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchSiteInfoPending: false,
        fetchSiteInfoError: action.data.error,
      };

    default:
      return state;
  }
}
