import axios from 'axios';
import _ from 'lodash';
import { API_REQUEST_URL } from '../../../common/api';
import { INVALID_CHART_ERROR_MSG } from '../component/MessageConstants';
import {
    FETCH_OVERRIDE_CHART_DETAIL_BEGIN,
    FETCH_OVERRIDE_CHART_DETAIL_SUCCESS,
    FETCH_OVERRIDE_CHART_DETAIL_FAILURE,
} from './constants';
import { getInvalidProduct } from './fetchProductInfo';
import { spliceInvalidProduct } from './sortObject';

export function fetchOverrideChartDetail(args = {},productNumber,index) {
    return (dispatch,getState) => {
        dispatch({
            type: FETCH_OVERRIDE_CHART_DETAIL_BEGIN,
        });

        const promise = new Promise((resolve, reject) => {
            const cognitoIdToken = getState().home.auth.cognitoIdToken;
            const cognitoToken = `Bearer ${cognitoIdToken}`;
            const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;  //need change to OGM token
            const doRequest = axios.post(`${API_REQUEST_URL}/ogmOrder/overrideChartDetail`, args, {
                headers: {
                    'Authorization': token,
                    'cognitoToken': cognitoToken
                }
            });
            let invalidProduct=[];
            doRequest.then(
                (res) => {
                    const result = res.data.mapData.results[0];
                    if (_.isEmpty(result.itemObjects)) {
                        invalidProduct = getInvalidProduct(productNumber, index, INVALID_CHART_ERROR_MSG);
                        dispatch({
                            type: FETCH_OVERRIDE_CHART_DETAIL_FAILURE,
                            data: { error: res.data.errorMessage, index},
                            invalidProduct: invalidProduct
                        });
                        reject(res.data.errorMessage);
                    } else {
                        dispatch({
                            type: FETCH_OVERRIDE_CHART_DETAIL_SUCCESS,
                            data: {...result,index},
                            invalidProduct: invalidProduct
                        });
                        resolve(result);
                    }
                },
                (err) => {
                    const errorMsg = err && err.response ? err.response.data ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.':'There is an error. Please refresh page or contact administrator.';
                    invalidProduct = getInvalidProduct(productNumber, index, errorMsg);
                    dispatch({
                        type: FETCH_OVERRIDE_CHART_DETAIL_FAILURE,
                        data: { error: errorMsg, index },
                        invalidProduct: invalidProduct
                    });
                    reject(err);
                },
            );
        });

        return promise;
    };
}

export function reducer(state, action) {
    switch (action.type) {
        case FETCH_OVERRIDE_CHART_DETAIL_BEGIN:
            return {
                ...state,
                fetchOverrideChartDetailPending: true,
                fetchOverrideChartDetailError: null,
            };

        case FETCH_OVERRIDE_CHART_DETAIL_SUCCESS:
            let invalidSuccess = spliceInvalidProduct(state.invalidProducts,action);
            return {
                ...state,
                overrideChartDetail: action.data,
                fetchOverrideChartDetailPending: false,
                fetchOverrideChartDetailError: null,
                invalidProducts: invalidSuccess
            };

        case FETCH_OVERRIDE_CHART_DETAIL_FAILURE:
            let invalidFailure = spliceInvalidProduct(state.invalidProducts,action);
            return {
                ...state,
                fetchOverrideChartDetailPending: false,
                fetchOverrideChartDetailError: action.data.error,
                invalidProducts: invalidFailure
            };

        default:
            return state;
    }
}
