import axios from 'axios';
import { API_REQUEST_URL } from '../../../common/api';
import _ from 'lodash';
import {
  FETCH_ASOH_BEGIN,
  FETCH_ASOH_SUCCESS,
  FETCH_ASOH_FAILURE,
  REMOVE_ASOH_ERROR_MSG,
  RESET_ALL_ITEM_QUANTITY,
  RESET_ASOH_ERROR_MSG,
} from './constants';
import { NO_INVENTORY_AVAILABLE } from '../component/MessageConstants';
import { addAsohErrorMsg } from './sortObject';
import { caseOrSplit, convertCaseToSplitQuatity, isDomBothCSEA } from './formatData';
import { DOM_VIEW_NAME_LOCATION } from '../../../common/constants';

export function fetchASOH(opco,supc,quantity,splitFlag,index,record) {
  return (dispatch,getState) => {
    dispatch({
      type: FETCH_ASOH_BEGIN,
    });
    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const isDomEnabled = getState().order.isDomSite;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const token = `Bearer ${window.sessionStorage.getItem("asohToken")}`;
      const domToken = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
      const asohRequest ={
        "businessUnitNumber": opco,
        "products": [
          {
            "splitFlag": splitFlag,
            "supc": supc
          }
        ]
      }
      const doRequest = axios.post(`${API_REQUEST_URL}/order/fetchAsoh?viewName=${opco}-${DOM_VIEW_NAME_LOCATION}`,asohRequest,{
        headers: {
          'Authorization': token,
          'cognitoToken': cognitoToken,
          'dom-Authorization': domToken
        }
      });
      
      doRequest.then(
        (res) => {
          let data = res.data;
          let asohMessage = {
            index: index,
            supc: supc,
            unit: splitFlag?'split':'case',
            message: '',
          };
          let isDomBothCsEa = false;
          if(isDomEnabled){
            if (isDomBothCSEA(record,quantity)) {
              let convertedQuatity = convertCaseToSplitQuatity(record);
              if(!_.isEmpty(data) && !_.isEmpty(data.products) && data.products[0].availableOnHand < convertedQuatity){
                asohMessage.message = NO_INVENTORY_AVAILABLE;
              }
              isDomBothCsEa = true;
            }else{
              if (!_.isEmpty(data) && !_.isEmpty(data.products) && data.products[0].availableOnHand < parseInt(quantity)) {
                asohMessage.message = NO_INVENTORY_AVAILABLE;
              }
            }
          }else{
            if(!_.isEmpty(data) && !_.isEmpty(data.products) && data.products[0].availableOnHand < parseInt(quantity)){
              asohMessage.message = NO_INVENTORY_AVAILABLE;
            }
          }
          
          dispatch({
            type: FETCH_ASOH_SUCCESS,
            data: asohMessage,
            isDomBothCsEa: isDomBothCsEa,
          });
          resolve(res);
        },
        (err) => {
          const errorMsg = err && err.response ? err.response.data ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.':'There is an error. Please refresh page or contact administrator.';
          const isDomBothCsEa = isDomBothCSEA(record,quantity);
          let asohMessage = {
            index: index,
            supc: supc,
            unit: splitFlag?'split':'case',
            message: errorMsg,
          };
          dispatch({
            type: FETCH_ASOH_FAILURE,
            data: asohMessage,
            isDomBothCsEa: isDomBothCsEa,
          });
          reject(err);
        },
      );
    });

    return promise;
  };
}
export function resetAsohErrorMsg() {
  return{
      type: RESET_ASOH_ERROR_MSG,
  };
}
export function resetAllItemQuantity() {
  return {
      type: RESET_ALL_ITEM_QUANTITY,
  };
}
export function reducer(state, action) {
  switch (action.type) {
    case FETCH_ASOH_BEGIN:
      return {
        ...state,
        fetchAsohPending: true,
        fetchAsohError: null,
      };

    case FETCH_ASOH_SUCCESS:
      const currentMsg = addAsohErrorMsg(state.asohValidationMsg,action.data,action.isDomBothCsEa);
      return {
        ...state,
        asohValidationMsg: currentMsg,
        fetchAsohPending: false,
        fetchAsohError: null,
      };

    case FETCH_ASOH_FAILURE:
      const  failedMessage = addAsohErrorMsg(state.asohValidationMsg,action.data,action.isDomBothCsEa);
      return {
        ...state,
        fetchAsohPending: false,
        fetchAsohError: action.data.error,
        asohValidationMsg: failedMessage,
      };

      case REMOVE_ASOH_ERROR_MSG:
      const removedMsg = state.asohValidationMsg.filter(item=>item.supc !== action.data.supc && item.index !== action.data.index);
      return {
        ...state,
        asohValidationMsg:removedMsg
      };
    case RESET_ALL_ITEM_QUANTITY:
      const resetProducts = JSON.parse(JSON.stringify(state.products));
      resetProducts.map(item=>{
        item['cs'] = '';
        item['spl'] = '';
      });
      return {
        ...state,
        products: resetProducts,
        asohValidationMsg:[]
      };
    case RESET_ASOH_ERROR_MSG:
      return {
      ...state,
      asohValidationMsg:[]
    };
    default:
      return state;
  }
}
