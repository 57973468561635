// This is the root reducer of the feature. It is used for:
//   1. Load reducers from each action in the feature and process them one by one.
//      Note that this part of code is mainly maintained by Rekit, you usually don't need to edit them.
//   2. Write cross-topic reducers. If a reducer is not bound to some specific action.
//      Then it could be written here.
// Learn more from the introduction of this approach:
// https://medium.com/@nate_wang/a-new-approach-for-managing-redux-actions-91c26ce8b5da.

import initialState from './initialState';
import { reducer as fetchOpcoDataReducer } from './fetchOpcoData';
import { reducer as fetchSupplierInfoReducer } from './fetchSupplierInfo';
import { reducer as fetchCustomerInfoReducer } from './fetchCustomerInfo';
import { reducer as fetchProductInfoReducer } from './fetchProductInfo';
import { reducer as fetchOverrideChartReportReducer } from './fetchOverrideChartReport';
import { reducer as fetchOverrideChartDetailReducer } from './fetchOverrideChartDetail';
import { reducer as submitOrderReducer } from './submitOrder';
import { reducer as deliveryMethodReducer } from './deliveryMethod';
import { reducer as fetchPriceBookEntryReducer } from './fetchPriceBookEntry';
import { reducer as createOrderHeaderReducer } from './createOrderHeader';
import { reducer as createOrderItemReducer } from './createOrderItem';
import { reducer as fetchWillCallTimesReducer } from './fetchWillCallTimes';
import  { reducer as createMuleOrderReducer } from './createMuleOrder';
import { reducer as getReferenceIdReducer } from './getReferenceId';
import { reducer as fetchSiteDataReducer } from './fetchSiteData';
import { reducer as fetchASOHReducer } from './fetchASOH';
import { reducer as fetchBulkASOHReducer } from './fetchBulkASOH';
import { reducer as fetchIsDomSiteReducer } from './fetchIsDomSite';
const reducers = [
  fetchOpcoDataReducer,
  fetchSupplierInfoReducer,
  fetchCustomerInfoReducer,
  fetchProductInfoReducer,
  deliveryMethodReducer,
  fetchOverrideChartReportReducer,
  fetchOverrideChartDetailReducer,
  submitOrderReducer,
  fetchPriceBookEntryReducer,
  createOrderHeaderReducer,
  createOrderItemReducer,
  fetchWillCallTimesReducer,
  createMuleOrderReducer,
  getReferenceIdReducer,
  fetchSiteDataReducer,
  fetchASOHReducer,
  fetchBulkASOHReducer,
  fetchIsDomSiteReducer,
];

export default function reducer(state = initialState, action) {
  let newState;
  switch (action.type) {
    // Handle cross-topic actions here
    default:
      newState = state;
      break;
  }
  return reducers.reduce((s, r) => r(s, action), newState);
}
