import axios from 'axios';
import moment from 'moment';
import { API_REQUEST_URL } from '../../../common/api';
import {
    FETCH_WILL_CALL_TIMES_BEGIN,
    FETCH_WILL_CALL_TIMES_SUCCESS,
    FETCH_WILL_CALL_TIMES_FAILURE,
    CLEAR_PICK_UP_TIME
} from './constants';
import _ from 'lodash';
import { sortObject } from './sortObject';
import { DATE_FROMAT_HH_MM_SS, DATE_FROMAT_MM_DD_YYYY, DATE_FROMAT_MM_DD_YYYY_HH_MM_SS, DATE_FROMAT_YYYY_MM_DD } from '../../../common/constants';
import { formatPickupTime } from './formatData';

export function fetchWillCallTimes(opco,dateNeeded) {
    return (dispatch,getState) => { // optionally you can have getState as the second argument
        dispatch({
            type: FETCH_WILL_CALL_TIMES_BEGIN,
        });
            const promise = new Promise((resolve, reject) => {
            const cognitoIdToken = getState().home.auth.cognitoIdToken;
            const cognitoToken = `Bearer ${cognitoIdToken}`;
            const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
            const doRequest = axios.get(`${API_REQUEST_URL}/susReferp/willCallTimes?opco=${opco}`, {
                headers: {
                    'Authorization': token,
                    'cognitoToken': cognitoToken
                }
            });
            doRequest.then((res) => {
                // let currentDay =  parseInt(moment(dateNeeded).format('E'));
                // let routingDay = currentDay + 1;
                // let willCallTime = [];
                // let current = moment().format(DATE_FROMAT_MM_DD_YYYY_HH_MM_SS);
                // if(!_.isEmpty(res.data.rows)){
                //     let rows = res.data.rows;
                //     rows.map(item => {
                //         if (parseInt(item.routingDay) === routingDay && item.orderType.replaceAll(' ', '') === 'WC' &&
                //         item.opco_nbr === opco && item.pickupTime.indexOf(':') > -1 ) {
                //             let cutoffStartTime = moment(moment(dateNeeded).format(DATE_FROMAT_MM_DD_YYYY) + item.cutoffStartTime,DATE_FROMAT_MM_DD_YYYY_HH_MM_SS);
                //             if(cutoffStartTime.isAfter(current)){
                //                 willCallTime.push(item);
                //             }
                //         }
                //     })
                //     sortObject(willCallTime, 'pickupTime', true);
                // }
                let willCallTime = formatPickupTime(res.data.rows,opco,dateNeeded);
                dispatch({
                    type: FETCH_WILL_CALL_TIMES_SUCCESS,
                    data: willCallTime,
                });
                resolve(res);
            },
                (err) => {
                    const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
                    dispatch({
                        type: FETCH_WILL_CALL_TIMES_FAILURE,
                        data: { error: errorMsg },
                    });
                    reject(err);
                },
            );
        });

        return promise;
    };
}
export function clearPickUpTime() {
    return (dispatch) => {
      dispatch({
        type: CLEAR_PICK_UP_TIME,
      });
    };
  }
export function reducer(state, action) {
    switch (action.type) {
        case FETCH_WILL_CALL_TIMES_BEGIN:
            // Just after a request is sent
            return {
                ...state,
                willCallTimes: [],
                fetchWillCallTimesPending: true,
                fetchWillCallTimesError: null,
            };

        case FETCH_WILL_CALL_TIMES_SUCCESS:
            // The request is success
            return {
                ...state,
                willCallTimes: action.data,
                fetchWillCallTimesPending: false,
                fetchWillCallTimesError: null,
            };

        case FETCH_WILL_CALL_TIMES_FAILURE:
            // The request is failed
            return {
                ...state,
                fetchWillCallTimesPending: false,
                fetchWillCallTimesError: action.data.error
            };
        case CLEAR_PICK_UP_TIME:
            // The request is failed
            return {
                ...state,
                willCallTimes:[]
            };
        default:
            return state;
    }
}
