import React from 'react';
import { BANNER } from '../../common/constants';

export default function Menu() {
  return (
    <div className="home-menu">
      {BANNER}
    </div>
  );
};

Menu.propTypes = {};
Menu.defaultProps = {};
