import _ from "lodash";
import moment from "moment";
import { DATE_FROMAT_MM_DD_YYYY, DATE_FROMAT_MM_DD_YYYY_HH_MM_SS } from "../../../common/constants";
import { NO_INVENTORY_AVAILABLE } from "../component/MessageConstants";
import { sortObject } from "./sortObject";

export const formatPickupTime = (pickupTimeRows, opco, dateNeeded) => {
    let willCallTime = [];
    if (!_.isEmpty(pickupTimeRows)) {
        let dayOfDateNeeded = parseInt(moment(dateNeeded).format('E'));
        const routingDay = dayOfDateNeeded + 1;
        const toDayTwelveClock = moment(moment().format(DATE_FROMAT_MM_DD_YYYY) + ' 12:00:00', DATE_FROMAT_MM_DD_YYYY_HH_MM_SS);
        pickupTimeRows.map(item => {
            if (parseInt(item.routingDay) === routingDay && item.orderType.replaceAll(' ', '') === 'WC' &&
                item.opco_nbr === opco && item.pickupTime.indexOf(':') > -1) {
                const cutoffStartTime = moment(moment(dateNeeded).format(DATE_FROMAT_MM_DD_YYYY) + ' ' + item.cutoffStartTime, DATE_FROMAT_MM_DD_YYYY_HH_MM_SS);
                if (cutoffStartTime.isAfter(moment())) {
                    //if dataNeeded is same today and current time is before 12 pm, will get pick up time that more than 2 and a half hours to current time
                    if (moment(dateNeeded).isSame(moment(), 'day') && (moment().isBefore(toDayTwelveClock) ||moment().isSame(toDayTwelveClock) )) {
                        const seekTime = moment().add(2.5, 'hours').format(DATE_FROMAT_MM_DD_YYYY_HH_MM_SS);
                        const itemPickupTime = moment(moment().format(DATE_FROMAT_MM_DD_YYYY) + ' ' + item.pickupTime, DATE_FROMAT_MM_DD_YYYY_HH_MM_SS);
                        if (itemPickupTime.isAfter(seekTime) || itemPickupTime.isSame(seekTime)) {
                            willCallTime.push(item);
                        }
                    }else if (moment(dateNeeded).isSame(moment(), 'day') && moment().isAfter(toDayTwelveClock)) {
                        //if dataNeeded is same today and current time is after 12 pm,will not allow to submit order
                        //not push pick up time
                    }else {
                        willCallTime.push(item);
                    }
                }
            }
        })
        sortObject(willCallTime, 'pickupTime', true);
    }
    return willCallTime;
}
export const caseOrSplit = (record)=>{
    if(record.isShipSplitOnly){
        return 'ea';
    }else if(!record.isShipSplitOnly && record.minimumSplit === 0){
        return 'cs';
    }else{
        return 'cs-ea';
    }
}
export const convertCaseToSplitQuatity =(record)=>{
    let inputCase = 0;
    let inputSplit = 0;
    if(!_.isEmpty(record.cs)){
        inputCase = record.cs;
    }
    if(!_.isEmpty(record.spl)){
        inputSplit = record.spl;
    }
    return (parseInt(inputCase) * parseInt(record.unitPerCase)) + parseInt(inputSplit);
}
export const paddingOrderReferenceNo =(referenceNo)=>{
        // let str = String(1000 + referenceNo);
        return referenceNo.padStart(7,0);
}
export const isDomBothCSEA = (record,quantity)=>{
    if(caseOrSplit(record) === 'cs-ea' && !_.isEmpty(quantity)){
        return true;
    }
    return false;
}
export const formatBulkAsohMessage = (products, asohResponse, errorMsg, isDomSite, asohRequest,failedMessage) => {
    if (asohRequest) {
        products.map(item => {
            const obj = asohRequest.filter(asohItem => item.id === asohItem.supc);
            obj.map(o => {
                    if (item.cs !== '' && !o.splitFlag) {
                        errorMsg.push({
                            index: item.index,
                            supc: item.id,
                            unit: 'case',
                            message: failedMessage,
                        });
                    }
                    if (item.spl !== '' && o.splitFlag) {
                        errorMsg.push({
                            index: item.index,
                            supc: item.id,
                            unit: 'split',
                            message: failedMessage,
                        });
                    }
            });
        });
    } else {
        products.map(item => {
            const obj = asohResponse.filter(asohItem => item.id === asohItem.supc);
            if (!_.isEmpty(obj) && obj !== undefined) {
                obj.map(o => {
                    if (isDomSite && caseOrSplit(item) === 'cs-ea') {
                        let convertedQuatity = convertCaseToSplitQuatity(item);
                        if (item.cs !== '' && (parseInt(convertedQuatity) > o.availableOnHand)) {
                            errorMsg.push({
                                index: item.index,
                                supc: item.id,
                                unit: 'case',
                                message: NO_INVENTORY_AVAILABLE,
                            });
                        }
                        if (item.spl !== '' && (parseInt(convertedQuatity) > o.availableOnHand)) {
                            errorMsg.push({
                                index: item.index,
                                supc: item.id,
                                unit: 'split',
                                message: NO_INVENTORY_AVAILABLE,
                            });
                        }
                    } else {
                        if (item.cs !== '' && (parseInt(item.cs) > o.availableOnHand)) {
                            errorMsg.push({
                                index: item.index,
                                supc: item.id,
                                unit: 'case',
                                message: NO_INVENTORY_AVAILABLE,
                            });
                        }
                        if (item.spl !== '' && (parseInt(item.spl) > o.availableOnHand)) {
                            errorMsg.push({
                                index: item.index,
                                supc: item.id,
                                unit: 'split',
                                message: NO_INVENTORY_AVAILABLE,
                            });
                        }
                    }
                });
            }
        });
    }
}