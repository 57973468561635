import React from 'react';
import { Table, Input, Button, Popconfirm, Form, message, InputNumber, Spin } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchProductInfo, addEmptyProduct, removeOneProduct, resetInvalidProduct,replaceProduct, setItemQuantity, fetchOverrideChartReport, fetchOverrideChartDetail} from './redux/actions';
import _, { isNumber } from 'lodash';
import ErrorMessage from './component/ErrorMessage';
import { BOTH_CASE_SPLIT_ZERO_ERROR_MSG, CAN_NOT_ADD_MULTIPLE_EMPTY_ITEM_ERROR_MSG, DUPLICATE_SUPC_ERROR_MSG, INVALID_CHART_ERROR_MSG, ONLY_CASE_ENABLED_ZERO_ERROR_MSG, ONLY_SPLIT_ENABLED_ZERO_ERROR_MSG, SUPC_ONLY_NUMBER_ALLOWED } from './component/MessageConstants';
import FormItem from 'antd/lib/form/FormItem';
import { isTodayOrNextDay } from './componentUtils';

class OrderDetailComponent extends React.Component {
  constructor(props) {
    super(props);
    this.getProductInfo = this.getProductInfo.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleInputBlur = this.handleInputBlur.bind(this);
    this.state = {
      // duplicateProduct: [],
      emptyProduct: 0,
      supcErrorMsg: {
        // itemKey,
        // message,
      },
      editableRow:{
        id:"",
        editable:false
      }
    }
  }

  componentDidUpdate() {
    const { products } = this.props;
  }

  handleInputChange = (field, value, record) => {
    const {products, setItemQuantity,validateItemQuantity,form} = this.props;
    if(typeof(value) === 'object') {
      value = value.target.value;
    }
    //if dateNeeded is not selected will not set quantity to record.
    const dateNeeded = form.current.getFieldValue("dateNeeded");
    if(dateNeeded === undefined){
      message.error('Please select Date Needed!');
      return;
    }else{
      setItemQuantity(field,value,record);
      validateItemQuantity(field, value, record);
    }
  }
 
  handleInputBlur = (key, event, record) => {
    const { products, invalidProducts,resetInvalidProduct,replaceProduct,duplicateProduct,itemErrorMesg,form,errorProduct} = this.props;
      //SUPC only accept numeric type
    // if(itemErrorMesg.length !== 0){
    //   return;
    // }
    if(!_.isEmpty(errorProduct) && errorProduct.findIndex(item=>item.index === record.index) > -1){
      return;
    }
    // if(!_.isEmpty(duplicateProduct) && duplicateProduct.findIndex(item=>item.index === record.index) > -1){
    //       return;
    // }    
    //site and Supplier Account required
    const opcoId = form.current.getFieldValue("site");
    const supplierAccount = form.current.getFieldValue("supplierAccount");
    if (_.isEmpty(opcoId) || _.isEmpty(supplierAccount)) {
      message.error('Please select Site and Supplier Account!');
      return;
    }
    //supc required
    if (_.isEmpty(event.target.value)) {
      message.error('Please input SUPC!');
      // if(invalidProducts.length !==0 ){
      //   resetInvalidProduct(record.index);
      // }
      // if(duplicateProduct.length !==0){
      //   this.props.handleChange("duplicateProduct", []);
      // }
      // replaceProduct(products[products.length-1].index);
      return;
    }
    if(!_.isEmpty(this.getDuplicateProduct(products,event.target.value,record.index))){
      return;
    }
    this.getProductInfo(record.index, opcoId, supplierAccount, event.target.value);
    
  }
  getDuplicateProduct = (products,value,index) =>{
    const {duplicateProduct,handleChange,replaceProduct } = this.props;
    let duplicateIndex = products.findIndex(item => item.id === value && item.index !== index);
    let newDupProduct={};
    if(duplicateIndex > -1){
      //have duplicate
      let idx = duplicateProduct.findIndex(item=>item.id === value && item.index === index);
      if(idx === -1){
        newDupProduct = {
          id:value,
          index: index,
          message: DUPLICATE_SUPC_ERROR_MSG
        } 
        replaceProduct(value,index);
      }
    }else{
      let idx = duplicateProduct.findIndex(item=>item.index === index);
      if(idx > -1 ){
          duplicateProduct.splice(idx,1);
        }
    }
    if(!_.isEmpty(newDupProduct)){
      let newProduct = [...duplicateProduct,newDupProduct];
      handleChange("duplicateProduct", newProduct);
    }
    return newDupProduct;
  }
  setEditableRow = (id,editable) =>{
    this.setState({
      editableRow:{
        id: id,
        editable: editable
      }
    })
  }
  handleDelete = (index) => {
    const {duplicateProduct,errorProduct} = this.props;
    this.props.removeOneProduct(index);
    let dIdx = duplicateProduct.findIndex(item=>item.index === index);
    if(dIdx > -1){
      duplicateProduct.splice(dIdx,1);
    }
    let errorIdx = errorProduct.findIndex(item=>item.index === index);
    if(errorIdx > -1){
      errorProduct.splice(errorIdx,1);
    }
    // handleChange("duplicateProduct", duplicateProduct);
  };

  handleAddItem = () => {
    const { products } = this.props;
    let emptyProduct = products.filter(item => item.id === '');
    if (emptyProduct.length !== 0) {
      message.error(CAN_NOT_ADD_MULTIPLE_EMPTY_ITEM_ERROR_MSG);
    } else {
      this.props.addEmptyProduct();
    }
    this.props.handleChange("duplicateProduct", []);

  };

  getProductInfo(index, opcoNumber, customerNumber, productNumber) {
    const { overrideChartReport, fetchProductInfo } = this.props;
    fetchProductInfo(index, opcoNumber, customerNumber, productNumber, overrideChartReport.chartId)
  }
  validateSUPC = (value,index) =>{
    const { handleChange,errorProduct,replaceProduct,resetInvalidProduct, duplicateProduct } = this.props;
    //before onchange remove the error in all list
    resetInvalidProduct(index)
    let duplicateIndex = duplicateProduct.findIndex(item=>item.index === index);
    if(duplicateIndex > -1){
      duplicateProduct.splice(duplicateIndex,1);
    }
    let nanProduct= {};
    let errorIndex = errorProduct.findIndex(item=>item.index === index);
   if(_.isEmpty(value)){
    replaceProduct(value,index);
    if(errorIndex > -1){
      errorProduct.splice(errorIndex,1);
    }
   }else{
    if(errorIndex === -1){
      if(isNaN(value)){
        nanProduct = {
                id:value,
                index: index,
                message: SUPC_ONLY_NUMBER_ALLOWED
            } 
       //remove product or replace product to empty.
        replaceProduct(value,index);
      }
    }else {
      if(!isNaN(value)){
        errorProduct.splice(errorIndex,1);
      }
    }
  }
    if(!_.isEmpty(nanProduct)){
      let newErrorProduct = [...errorProduct,nanProduct];
      handleChange("errorProduct",newErrorProduct);
    }
  }
  onQuantityKeyDown = (e) => {
    //backspace keyCode = 8
    if(!(/[\d]/.test(e.key)) && e.keyCode !== 8 && e.keyCode !== 9 ) {
      e.preventDefault();
    }
  }
  limitQuantity = (value) =>{
    if(value.length >1 && value.indexOf('0') === 0){
      return value.replace('0','');
    }
    return value.toString().slice(0,3);
  }
  getIndex = (object,index) =>{
    return object.findIndex(item => item.index ===  index);
  }
  validateAsoh = (e,splitFlag,record)=>{
    const { validateASOH,form } = this.props;
    const opco = form.current.getFieldValue('site');
    const dateNeeded = form.current.getFieldValue('dateNeeded');
    
    if(dateNeeded === undefined){
      message.error('Please select Date Needed.');
      return;
    }
    if(record.id === ''){
      message.error('Please Input Supc.');
      return;
    }
    //If the order is a Same day or Next day Order,will call to ASOH Service to get the Inventory details
    if(isTodayOrNextDay(dateNeeded)){
      validateASOH(e.target.value,splitFlag,record,opco);
    }
  }
  findAsohMsgIndex = (object,index,unit,id) =>{
    const {form} = this.props;
    const dateNeeded = form.current.getFieldValue("dateNeeded");
    return object.filter(item => item.index ===  index && item.unit === unit && item.supc === id && isTodayOrNextDay(dateNeeded));
  }
  render() {
    const { products,
            fetchProductInfoError, overrideChartDetail,
            fetchOverrideChartDetailError, duplicateProduct, fetchProductInfoPending,invalidProducts,itemErrorMesg,quantityError,overrideChartReport,fetchOverrideChartReportError,
            errorProduct,
            asohValidationMsg
          }= this.props;
    const columns = [
      {
        title: 'SUPC',
        dataIndex: 'id',
        key: 'id',
        width: '16%',
        editable: true,
        render: (value, record, index) => {
          return (
            <div>
              <Input defaultValue={record.id} onBlur={(e) => this.handleInputBlur('id', e, record)} style={{width:180}}
                maxLength={7}
                onChange={(e) => this.validateSUPC(e.target.value,record.index)}
              />
              {
                !_.isEmpty(errorProduct) && this.getIndex(errorProduct,record.index) >-1?
                 <ErrorMessage message={errorProduct[this.getIndex(errorProduct,record.index)].message} /> :
                  !_.isEmpty(duplicateProduct) && this.getIndex(duplicateProduct,record.index) >-1?
                  <ErrorMessage message={duplicateProduct[this.getIndex(duplicateProduct,record.index)].message} /> :
                  record.isProprietary && !_.isEmpty(invalidProducts) && this.getIndex(invalidProducts,record.index) > -1?
                    <ErrorMessage message={invalidProducts[0].message} /> :
                  !_.isEmpty(invalidProducts) && this.getIndex(invalidProducts,record.index) >-1?
                 <ErrorMessage message={invalidProducts[this.getIndex(invalidProducts,record.index)].message} /> :
                  ''
              }
            </div>
          )

        },
      },
      {
        title: 'Case',
        dataIndex: 'cs',
        key: 'cs',
        width: '16%',
        editable: true,
        render: (value, record) =>
          <div>
              <Input type="number" min={0} max={999} 
                style={{width:180}} maxLength={3}
                value={this.limitQuantity(value)}
                disabled={record.isShipSplitOnly}
                onChange={(e) => this.handleInputChange('cs', e, record)} 
                onKeyDown={e => this.onQuantityKeyDown(e)}
                onBlur={(e)=>this.validateAsoh(e,false,record)}
              />
              {
                !_.isEmpty(asohValidationMsg) && !_.isEmpty(this.findAsohMsgIndex(asohValidationMsg,record.index,'case',record.id)) && this.findAsohMsgIndex(asohValidationMsg,record.index,'case',record.id)[0].unit === 'case'?
                <ErrorMessage message={this.findAsohMsgIndex(asohValidationMsg,record.index,'case',record.id)[0].message} />:null
              }
          </div>
      },
      {
        title: 'Split',
        dataIndex: 'spl',
        key: 'spl',
        width: '16%',
        editable: true,
        render: (value, record) => 
        <div>
            <Input type="number" min={0} max={999} 
            style={{width:180}} maxLength={3}
            value={this.limitQuantity(value)}
            disabled={ !record.isShipSplitOnly && record.minimumSplit === 0}
            onChange={(e) => this.handleInputChange('spl', e.target.value, record)}
            onKeyDown={e => this.onQuantityKeyDown(e)}
            onBlur={(e)=>this.validateAsoh(e,true,record)}
            />
            {
                 !_.isEmpty(asohValidationMsg) && !_.isEmpty(this.findAsohMsgIndex(asohValidationMsg,record.index,'split',record.id)) && this.findAsohMsgIndex(asohValidationMsg,record.index,'split',record.id)[0].unit === 'split'?
                 <ErrorMessage message={this.findAsohMsgIndex(asohValidationMsg,record.index,'split',record.id)[0].message} />:null
              }
          </div>
      },
      {
        title: 'NAME',
        key: 'name',
        dataIndex: 'name',
        width: '35%',
        render: (value, record) => record.name
      },
      {
        title: 'OPERATION',
        dataIndex: 'operation',
        width: '10%',
        render: (_, record) =>
          this.props.products.length >= 1 ? (
            <Popconfirm title="Are you sure to delete?" onConfirm={() => this.handleDelete(record.index)}>
              <a>Delete</a>
            </Popconfirm>
          ) : null,
      },
    ];
    return (
      <div className="order-detail">
              <Button
                onClick={this.handleAddItem}
                type="green"
                size="small"
                style={{
                  marginTop: 16,
                  marginBottom: 16,
                  marginLeft: 6
                }}
                disabled ={duplicateProduct !== null && duplicateProduct.length !== 0
                  || invalidProducts.length !== 0 || fetchProductInfoPending || itemErrorMesg || quantityError
                  || !_.isEmpty(errorProduct)
                }
              >
                Add Item
           </Button>
          <Table
            rowKey={record => record.id + `_` + record.index}
            bordered
            dataSource={products}
            columns={columns}
            pagination={{ pageSize: 5 }}
            size="small"
            rowClassName="order-detail-table-row"
          />
      </div>
    );
  }
}

OrderDetailComponent.propTypes = {
  opcos: PropTypes.object,
  fetchProductInfoError: PropTypes.string,
  overrideChartReport: PropTypes.object,
  overrideChartDetail: PropTypes.array,
  fetchOverrideChartDetailError: PropTypes.string
};

OrderDetailComponent.defaultProps = {};

const mapStateToProps = (state) => {
  const {
    opcos,
    // products,
    fetchProductInfoError,
    overrideChartReport,
    overrideChartDetail,
    fetchOverrideChartDetailError,
    fetchOverrideChartReportError,
    asohValidationMsg,
  } = state.order;

  // const productsWithIndex = _.map(products, (product, index) => {
  //   return { ...product, index };
  // }
  // );

  return {
    opcos,
    // products: productsWithIndex,
    fetchProductInfoError: fetchProductInfoError,
    overrideChartReport: overrideChartReport,
    overrideChartDetail: overrideChartDetail,
    fetchOverrideChartDetailError: fetchOverrideChartDetailError,
    fetchOverrideChartReportError,
    asohValidationMsg
  };
};

const mapDispatchToProps = dispatch => {
  return {
    fetchProductInfo: (index, opcoNumber, customerNumber, productNumber, chartId) => {
      dispatch(fetchProductInfo(index, opcoNumber, customerNumber, productNumber, chartId));
    },
    addEmptyProduct: () => {
      dispatch(addEmptyProduct());
    },
    removeOneProduct: (index) => {
      dispatch(removeOneProduct(index));
    },
    resetInvalidProduct:(value)=>{
      dispatch(resetInvalidProduct(value));
    },
    replaceProduct:(value,index)=>{
      dispatch(replaceProduct(value,index));
    },    
    setItemQuantity:(field,value,record)=>{
      dispatch(setItemQuantity(field,value,record));
    },
  };
};

const OrderDetail = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetailComponent);

export default OrderDetail;