//export const DEV_API_REQUEST_URL = `https://vendorbroker-bff-dev.sales-operations-np.us-east-1.aws.sysco.net/vbf`;
export const DEV_API_REQUEST_URL = `https://vendorbroker-bff-dev.sales-operations-np.sysco-go.com/vbf`;
export const QA_API_REQUEST_URL = `https://vendorbroker-bff-dev.sales-operations-np.sysco-go.com/vbf`;
export const PROD_API_REQUEST_URL = `https://vendorbroker-bff.sales-operations.sysco-go.com/vbf`;
export const LOCAL_API_REQUEST_URL = `https://vendorbroker-bff-dev.sales-operations-np.sysco-go.com/vbf`;

export const DEV_ENDPOINT = `https://vbf-bff-dev.ogm-np.us-east-1.aws.sysco.net`;
export const QA_ENDPOINT = `https://vbf-bff-qa.ogm-np.us-east-1.aws.sysco.net`;
export const PROD_ENDPOINT = `https://vbf-bff-prod.ogm.us-east-1.aws.sysco.net`;

//export const DEV_REDIRECT = `https://vendorbroker-dev.sales-operations-np.us-east-1.aws.sysco.net`;
export const DEV_REDIRECT = `https://vendorbroker-dev.sales-operations-np.sysco-go.com`;
export const QA_REDIRECT = `https://vendorbroker-dev.sales-operations-np.sysco-go.com`;
export const PROD_REDIRECT = `https://vendor-broker-orders.sales-operations.sysco-go.com`;
export const LOCAL_REDIRECT = `http://localhost:3100/`;

export const AUTH_DOMAIN = `vendorbroker-dev.auth.us-east-1.amazoncognito.com`;
export const PROD_AUTH_DOMAIN = `vendorbroker.auth.us-east-1.amazoncognito.com`;

export const DEV_WS_REQUEST_SITES_URL = `https://ict-webservices-dev.ogm-np.us-east-1.aws.sysco.net/ICT`;
export const QA_WS_REQUEST_SITES_URL = `https://ict-webservices-qa.ogm-np.us-east-1.aws.sysco.net/ICT`;
export const PROD_WS_REQUEST_SITES_URL = `https://ict-webservices-prod.ogm.us-east-1.aws.sysco.net/ICT`;