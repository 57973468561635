import { message } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import resolve from 'resolve';
import { API_REQUEST_URL } from '../../../common/api';
import {
    PLACE_ORDER_BEGIN,
    PLACE_ORDER_SUCCESS,
    PLACE_ORDER_FAILURE,
} from './constants';
import { createOrderHeader } from './createOrderHeader';
import { createOrderItem } from './createOrderItem';
import { fetchPriceBookEntry } from './fetchPriceBookEntry';


export function submitOrder(requestData = {}) {
    // console.log('submitOrder-->' + requestData);
    return (dispatch) => {
        dispatch({
            type: PLACE_ORDER_BEGIN,
        });

        const opcoId = '067'; //requestData.opcoId;
        const supplierAccount = requestData.supplierAccount;
        const orderHeader = requestData.orderHeader;
        const products = requestData.products;
        const itemError = [];
        const itemSucc = [];
        const orderItemRes = [];
        
       return new Promise((resolve, reject) => {
            dispatch(createOrderHeader(orderHeader)).then((res)=>{
                resolve(res);
            },(err) =>{
                reject(err);
            });
        }).then((result) => {
            const orderHeaderId = result.data.data.id;
            return new Promise((resolve, reject) => {
                products.map((item) =>{
                    const cs = item.cs;
                    const spl = item.spl;
                    const supc = item.id;
                    const unique_ID__c = opcoId + `-` + supc;                   
                    dispatch(fetchPriceBookEntry(opcoId, supc)).then((res)=>{
                        //console.log('priceBookEntryId-->res-->' + JSON.stringify(res));
                        const priceBookEntryId = res.data.data[0].id;
                        const orderItem = {
                            "Line_Number__c": 1,
                            "OrderId": orderHeaderId,     //orderHeaderId
                            "Customer_Response__c": "Price",
                            "Product2": {
                                "Unique_ID__c": unique_ID__c    //unique_ID__c
                            },
                            "PriceBookEntryId": priceBookEntryId,   //priceBookEntryId
                            "UnitPrice": 10.0,                  //spl?
                            "Quantity": 1                       //cs?
                        }
                        dispatch(createOrderItem(orderItem)) .then((res)=>{
                            resolve(res.data.data.id);
                        },(err) =>{
                            reject(err);
                        });
                    });
                });                 
            });
        }).then((res)=>{
            message.info("Submit Order Success!")
            dispatch({
                type: PLACE_ORDER_SUCCESS,
                data: res.data,
              });
        },(err)=>{
            message.error(err);
            dispatch({
                type: PLACE_ORDER_FAILURE,
                data: { error: err },
              });
        });      
        
    };
}
export function reducer(state, action) {
    switch (action.type) {
        case PLACE_ORDER_BEGIN:
            return {
                ...state,
                placeOrderPending: true,
                placeOrderError: null,
            };

        case PLACE_ORDER_SUCCESS:
            return {
                ...state,
                placeOrderResponse: action.data,
                placeOrderPending: false,
                placeOrderError: null,
            };

        case PLACE_ORDER_FAILURE:
            return {
                ...state,
                placeOrderPending: false,
                placeOrderError: action.data.error,
            };

        default:
            return state;
    }
}
