import axios from 'axios';
import { API_REQUEST_URL } from '../../../common/api';
import {
  FETCH_SUPPLIER_INFO_BEGIN,
  FETCH_SUPPLIER_INFO_SUCCESS,
  FETCH_SUPPLIER_INFO_FAILURE,
  CLEAR_SUPPLIER_INFO
} from './constants';
import _ from 'lodash';
import { fetchOverrideChartReport } from './fetchOverrideChartReport';
import { NETWORK_ERROR_MSG, SHIP_TO_CUSTOMER_STATUS, SUPPLIER_ACCOUNT_ERROR_MSG, SUPPLIER_ACCOUNT_STATUS } from '../component/MessageConstants';

export function fetchSupplierInfo(opco, supplierNumber) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_SUPPLIER_INFO_BEGIN,
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/account/supplier?opco=${opco}&supplier=${supplierNumber}`, {
        headers: {
          'Authorization': token,
          'cognitoToken': cognitoToken
        }
      });
      doRequest.then(
        (res) => {
          if (SHIP_TO_CUSTOMER_STATUS.indexOf(res.data.shipToCustomerStatus) > -1 
              && SUPPLIER_ACCOUNT_STATUS.indexOf(res.data.customerType) > -1) {
            //call override chart report api
            // const agrs = {
            //   "chartType": "I",
            //   "clientId": "abc",
            //   "instanceURL": "abc",
            //   "opcoId": "067", //"067" this.props.opcoId
            //   "customerNumber": "979906" //"979906" //supplierAccount
            // }
            // dispatch(fetchOverrideChartReport(agrs)).then((data) => {
            //   // console.log('Get Override Chart Report success-->' + JSON.stringify(data));
            // }, (err) => {
            //   // console.log('Get Override Chart Report Failed-->' + JSON.stringify(err));
            // });
            dispatch({
              type: FETCH_SUPPLIER_INFO_SUCCESS,
              data: res.data
            });
          } else {
            dispatch({
              type: FETCH_SUPPLIER_INFO_FAILURE,
              data: { error: SUPPLIER_ACCOUNT_ERROR_MSG, errorStatus: 'error' },
            });
          }
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorCode = err && err.response?err.response.status:err;
          if(errorCode === 404 || errorCode === 400 || errorCode === 401){
            dispatch({
              type: FETCH_SUPPLIER_INFO_FAILURE,
              data: { error: SUPPLIER_ACCOUNT_ERROR_MSG, errorStatus: 'error' },
            });
          }else if(errorCode === 401){
            const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
            dispatch({
              type: FETCH_SUPPLIER_INFO_FAILURE,
              data: { error: errorMsg, errorStatus: 'error' },
            });
          }else{
            dispatch({
              type: FETCH_SUPPLIER_INFO_FAILURE,
              data: { error: NETWORK_ERROR_MSG, errorStatus: 'error' },
            });
          }
          reject(err);
        },
      );
    });

    return promise;
  };
}

export function clearSupplierInfo() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_SUPPLIER_INFO,
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case FETCH_SUPPLIER_INFO_BEGIN:
      // Just after a request is sent
      return {
        ...state,
        supplier: {},
        fetchSupplierInfoPending: true,
        fetchSupplierInfoError: null,
      };

    case FETCH_SUPPLIER_INFO_SUCCESS:
      // The request is success
      return {
        ...state,
        supplier: action.data,
        fetchSupplierInfoPending: false,
        fetchSupplierInfoError: null,
        supplierErrorStatus: 'success'
      };

    case FETCH_SUPPLIER_INFO_FAILURE:
      // The request is failed
      return {
        ...state,
        fetchSupplierInfoPending: false,
        fetchSupplierInfoError: action.data.error,
        supplierErrorStatus: action.data.errorStatus
      };

    case CLEAR_SUPPLIER_INFO:
      return {
        ...state,
        supplier: {},
        fetchSupplierInfoError: null,
        supplierErrorStatus: 'success'
      };

    default:
      return state;
  }
}
