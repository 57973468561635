import moment from 'moment';
import _ from 'lodash';
import { DATE_FROMAT_MM_DD_YYYY } from '../../common/constants';
export const isTodayOrNextDay = (dateNeeded) => {
    const tomorrow = moment(new Date()).add(1,'days').format(DATE_FROMAT_MM_DD_YYYY);
    return (moment(dateNeeded).isSame(moment().format(DATE_FROMAT_MM_DD_YYYY),'day')) || (moment(dateNeeded).format(DATE_FROMAT_MM_DD_YYYY) === tomorrow);
}
export const bulkAsohRequestBody = (products)=>{
    let productPayload =[];
    products.map(product=>{
        if(!_.isEmpty(product.cs)){
            productPayload.push({
                "splitFlag": false,
                "supc":product.id
            });
        }
        if(!_.isEmpty(product.spl)){
            productPayload.push({
                "splitFlag": true,
                "supc":product.id
            });
        }
    })
    return {
        "businessUnitNumber": products[0].opcoId,
        "products": productPayload
    }
}
export const isTodayAndAfterNoon = (dateNeeded,threshold) => {
    return moment(dateNeeded).isSame(moment().format(DATE_FROMAT_MM_DD_YYYY),'day') && moment().isAfter(threshold);
}
export const isTodayAndNotAfterNoon = (dateNeeded,threshold) => {
    return moment(dateNeeded).isSame(moment().format(DATE_FROMAT_MM_DD_YYYY),'day') &&(moment().isBefore(threshold) || moment().isSame(threshold));
}