import axios from 'axios';
import { API_REQUEST_URL } from '../../../common/api';
import {
  FETCH_PRODUCT_INFO_BEGIN,
  FETCH_PRODUCT_INFO_SUCCESS,
  FETCH_PRODUCT_INFO_FAILURE,
  ADD_EMPTY_PRODUCT_INFO,
  REMOVE_ONE_PRODUCT,
  CLEAR_PRODUCT_INFO,
  RESET_INVALID_PRODUCT,
  REPLACE_PRODUCT,
  SET_ITEM_QUANTITY,
  SET_ITEM_QUANTITY_BEGIN,
  SET_ITEM_QUANTITY_SUCCESS
} from './constants';
import _ from 'lodash';
import { fetchOverrideChartReport } from './fetchOverrideChartReport';
import { fetchOverrideChartDetail } from './fetchOverrideChartDetail';
import { fetchPriceBookEntry } from './fetchPriceBookEntry';
import { INVALID_SUPC_ERROR_MSG, ITEMS_STOCK_INDICATOR, ITEM_STATUS_CODES, ITEM_STATUS_ERROR_MSG, ITEM_STOCK_INDICATOR_ERROR_MSG } from '../component/MessageConstants';
import { spliceInvalidProduct } from './sortObject';
const empty_product = {
  id: '',
  cs: '',
  spl: '',
  name: ''
};

export function fetchProductInfo(index, opco, customersNumber, productNumber, chartId) {
  return (dispatch,getState) => { // optionally you can have getState as the second argument
    dispatch({
      type: FETCH_PRODUCT_INFO_BEGIN,
      // data: {
      //   index,
      //   id: productNumber,
      //   cs: '',
      //   spl: ''
      // }
    });

    const promise = new Promise((resolve, reject) => {
      const cognitoIdToken = getState().home.auth.cognitoIdToken;
      const cognitoToken = `Bearer ${cognitoIdToken}`;
      const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
      const doRequest = axios.get(`${API_REQUEST_URL}/order/product?opco=${opco}&customer=${customersNumber}&product=${productNumber}`, {
        headers: {
          'Authorization': token,
          'cognitoToken': cognitoToken
        }
      });
      let invalidProduct = {};
      doRequest.then(
        (res) => {
          //if statusCode - A, N - "This item is not Active"
          //if Item stockIndicator - S - "This Item is not Stocked"
          if (ITEM_STATUS_CODES.indexOf(res.data.statusCode) === -1) {
            invalidProduct = getInvalidProduct(productNumber, index, ITEM_STATUS_ERROR_MSG);
          } else if (ITEMS_STOCK_INDICATOR !== res.data.stockIndicator) {
            invalidProduct = getInvalidProduct(productNumber, index, ITEM_STOCK_INDICATOR_ERROR_MSG)
          } else {
            //call chart api
            const isProprietary = res.data.isProprietary;
            if(isProprietary){
              const chartReportAgrs = {
                "chartType": "I",
                "clientId": "abc",
                "instanceURL": "abc",
                "opcoId": opco, //"067" this.props.opcoId
                "customerNumber": customersNumber //"979906" //supplierAccount
              }
              dispatch(fetchOverrideChartReport(chartReportAgrs,productNumber,index)).then((data) => {
                // console.log('Get Override Chart Report success-->' + JSON.stringify(data));
                const chartId = data.chartId;
                if(!_.isEmpty(chartId)){
                  const agrs = {
                    "chartId": chartId, //"CONCIERGE", //overrideChartReport.chartId,
                    "chartType": "I",
                    "clientId": "abc",
                    "instanceURL": "abc",
                    "opcoId": opco,   //"067" need change to selected opco
                    "supc": productNumber,  //"1943004" productNumber,
                    "pageIndex": "0",
                    "itemDescription": "",
                    "newRequest": "Y"
                  }
                  dispatch(fetchOverrideChartDetail(agrs,productNumber,index)).then((res) => {
                    // console.log('Get Override Chart Detail success-->' + JSON.stringify(res));
                  }, (err) => {
                    // console.log('Get Override Chart Detail Failed-->' + JSON.stringify(err));
                  });
                }
              }, (err) => {
                // console.log('Get Override Chart Report Failed-->' + JSON.stringify(err));
              });
            }
          }
          dispatch({
            type: FETCH_PRODUCT_INFO_SUCCESS,
            data: { ...res.data, index, cs: '', spl: ''},
            invalidProduct: invalidProduct
          });
          resolve(res);
        },
        // Use rejectHandler as the second argument so that render errors won't be caught.
        (err) => {
          const errorCode = err && err.response?err.response.status:err;
          const errorMsg = err && err.response ? err.response.data ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.':'There is an error. Please refresh page or contact administrator.';
          // const errorMsg = err && err.response.status === 404 ? INVALID_SUPC_ERROR_MSG:'';
          if(errorCode === 404){
            invalidProduct = getInvalidProduct(productNumber, index, INVALID_SUPC_ERROR_MSG);
            dispatch({
              type: FETCH_PRODUCT_INFO_FAILURE,
              data: { error: INVALID_SUPC_ERROR_MSG, index},
              invalidProduct: invalidProduct 
            });
          }else if(errorCode === 401){
            invalidProduct = getInvalidProduct(productNumber, index, errorMsg);
            dispatch({
              type: FETCH_PRODUCT_INFO_FAILURE,
              data: { error: errorMsg, index},
              invalidProduct: invalidProduct 
            });
          }else if(errorCode === 400){
            let errorMsg400 = 'Bad Request. Please make sure Site,Broker Account,SUPC are available.'
            invalidProduct = getInvalidProduct(productNumber, index, errorMsg400);
            dispatch({
              type: FETCH_PRODUCT_INFO_FAILURE,
              data: { error: errorMsg400, index},
              invalidProduct: invalidProduct 
            });
          }else{
            invalidProduct = getInvalidProduct(productNumber, index, "");
            dispatch({
              type: FETCH_PRODUCT_INFO_FAILURE,
              data: { error: "", index},
              invalidProduct: invalidProduct 
            });
          }
          
          reject(err);
        },
      );
    });

    return promise;
  };
}
export function getInvalidProduct(productNumber, index, errorMessage) {
  return {
    id: productNumber,
    index: index,
    message: errorMessage
  }
}
export function addEmptyProduct() {
  return (dispatch) => {
    dispatch({
      type: ADD_EMPTY_PRODUCT_INFO,
    });
  };
}

export function removeOneProduct(index) {
  return (dispatch) => {
    dispatch({
      type: REMOVE_ONE_PRODUCT,
      data: index
    });
  };
}

export function clearProductInfo() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_PRODUCT_INFO,
    });
  };
}
export function resetInvalidProduct(value) {
  return (dispatch) => {
    dispatch({
      type: RESET_INVALID_PRODUCT,
      data: value
    });
  };
}
export function replaceProduct(value,index) {
  return (dispatch) => {
    dispatch({
      type: REPLACE_PRODUCT,
      value: value,
      index: index
    });
  };
}
export function setItemQuantity(field,value,record){
  return (dispatch) => {
    dispatch({
      type: SET_ITEM_QUANTITY_BEGIN
    });
    dispatch({
      type: SET_ITEM_QUANTITY_SUCCESS,
      field: field,
      value: value,
      record: record
    });
  };
}
export function reducer(state, action) {
  let product = [];
  let products = [];
  let currentTime = new Date().getTime();
  switch (action.type) {
    case FETCH_PRODUCT_INFO_BEGIN:
      // state.products.splice(action.data.index, 1, action.data);
      return {
        ...state,
        products: state.products,
        fetchProductInfoPending: true,
        fetchProductInfoError: null,
      };

    case FETCH_PRODUCT_INFO_SUCCESS:
      let addIndex = state.products.findIndex(item=>item.index === action.data.index);
      state.products.splice(addIndex, 1, action.data);
      let invalidSuccess = spliceInvalidProduct(state.invalidProducts,action);
      //if input a supc and have got asoh error msg,then change supc to another supc,will remove that's asoh error
      let asohErrorIndex = state.asohValidationMsg.findIndex(item=>item.index === action.data.index);
      if(asohErrorIndex >= 0){
        state.asohValidationMsg.splice(asohErrorIndex, 1);
      }
      return {
        ...state,
        products: state.products,
        fetchProductInfoPending: false,
        fetchProductInfoError: null,
        invalidProducts: invalidSuccess,
        asohValidationMsg: state.asohValidationMsg
      };

    case FETCH_PRODUCT_INFO_FAILURE:
      // The request is failed
      let invalidFailure = spliceInvalidProduct(state.invalidProducts,action);
      return {
        ...state,
        invalidProducts: invalidFailure,
        fetchProductInfoPending: false,
        fetchProductInfoError: action.data.error,
      };

    case ADD_EMPTY_PRODUCT_INFO:
      // let currentTime = new Date().getTime();
      // products = [...state.products, { ...empty_product, index: currentTime }];
      products = [{...empty_product,index:currentTime},...state.products];
      return {
        ...state,
        products: products
      };

    case REMOVE_ONE_PRODUCT:
      let newProducts = state.products.filter((item) => item.index !== action.data);
      // state.products.splice(action.data, 1);
      let invalidProducts =  state.invalidProducts.filter((item) => item.index !== action.data);
      //if delete one product will remove the asohErrorMsg
      let asohValidationMsg =  state.asohValidationMsg.filter((item) => item.index !== action.data);
      return {
        ...state,
        products: newProducts,
        // fetchProductInfoError: null,
        orderItem: {},
        invalidProducts: invalidProducts,
        asohValidationMsg: asohValidationMsg
      };

    case CLEAR_PRODUCT_INFO:
      return {
        ...state,
        products: [{...empty_product,index:currentTime}],
        fetchProductInfoError: null,
        orderItem: {},
        invalidProducts: []
      };
    case RESET_INVALID_PRODUCT:
      let resetInvalidProduct =  state.invalidProducts.filter((item) => item.index !== action.data);
      return {
        ...state,
        invalidProducts: resetInvalidProduct
      };

    case REPLACE_PRODUCT:
      // state.products[action.index]['id'] = '';
      // if(action.index === 0){
      //   products = [...state.products, empty_product];
      // // }
      // // else{
        let replaceProduct = {
          index: action.index,
          id: action.value,
          cs: '',
          spl: '',
          name: ''
        };
        const replaceIndex = state.products.findIndex(item => item.index === action.index);
        state.products[replaceIndex] =  replaceProduct;
        // state.products.splice(action.index, 1);
      // }
      return {
        ...state,
        products: state.products
      };
    case SET_ITEM_QUANTITY_BEGIN:
      return {
        ...state,
        setItemQuantityPending: true
      };
    case SET_ITEM_QUANTITY_SUCCESS:
      const currIndex = state.products.findIndex(item => item.index === action.record.index);
      if (currIndex > -1) {
        state.products[currIndex][action.field] = action.value;
      }
      return {
        ...state,
        products: state.products,
        setItemQuantityPending: false
      };
    default:
      return state;
  }
}
