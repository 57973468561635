import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import './styles/index.less';
import { config } from './common/api';

// Amplify.configure({
// 	Auth: {
// 		mandatorySignIn: true,
// 		region: config.default.cognito.REGION,
// 		userPoolId: config.default.cognito.USER_POOL_ID,
// 		identityPoolId: config.default.cognito.IDENTITY_POOL_ID,
// 		userPoolWebClientId: config.default.cognito.APP_CLIENT_ID,
// 		oauth: config.default.oauth
// 	},
// 	API: {
// 		endpoints: [
// 			{
// 				name: config.default.endpoints[0].name,
// 				endpoint: config.default.endpoints[0].endpoint,
// 				service: config.default.endpoints[0].service,
// 				region: config.default.endpoints[0].region
// 			}
// 		]
// 	}
// });

//setup user and token data to session vars
// export function signin() {
// 	const idToken = 'rTBHUP8GT42LdApW4MD2LHLE87LU';
// 	window.sessionStorage.setItem('cognitoIdToken', idToken);

// 	const supplierCustomerToken = '0JjPRzTZirxxRIE19GJD0APyHuvR';
// 	window.sessionStorage.setItem('supplierCustomerToken', supplierCustomerToken);
// }

// signin();

ReactDOM.render(<Root />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
