import axios from 'axios';
import { API_REQUEST_URL } from '../../../common/api';
import _ from 'lodash';
import {
    FETCH_IS_DOM_SITE_BEGIN,
    FETCH_IS_DOM_SITE_SUCCESS,
    FETCH_IS_DOM_SITE_FAILURE,
} from './constants';
export function fetchIsDomSite(site) {
    return (dispatch, getState) => {
        dispatch({
            type: FETCH_IS_DOM_SITE_BEGIN,
        });

        const promise = new Promise((resolve, reject) => {
            const cognitoIdToken = getState().home.auth.cognitoIdToken;
            const cognitoToken = `Bearer ${cognitoIdToken}`;
            const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
            const doRequest = axios.get(`${API_REQUEST_URL}/order/isDomEnabled?site=${site}`, {
                headers: {
                    'Authorization': token,
                    'cognitoToken': cognitoToken
                }
            });
            doRequest.then(
                (res) => {
                    let data = res.data;
                    dispatch({
                        type: FETCH_IS_DOM_SITE_SUCCESS,
                        data: res.data,
                    });
                    resolve(data);
                },
                (err) => {
                    const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
                    dispatch({
                        type: FETCH_IS_DOM_SITE_FAILURE,
                        data: { error: errorMsg },
                    });
                    reject(err);
                },
            );
        });
        return promise;
    };
}
export function reducer(state, action) {
    switch (action.type) {
        case FETCH_IS_DOM_SITE_BEGIN:
            return {
                ...state,
                fetchIsDomSitePending: true,
                fetchIsDomSiteError: null,
            };

        case FETCH_IS_DOM_SITE_SUCCESS:
            return {
                ...state,
                isDomSite: action.data,
                fetchIsDomSitePending: false,
                fetchIsDomSiteError: null,
            };

        case FETCH_IS_DOM_SITE_FAILURE:
            return {
                ...state,
                fetchIsDomSitePending: false,
                fetchIsDomSiteError: action.data.error,
            };
        default:
            return state;
    }
}
