import axios from 'axios';
import _ from 'lodash';
import { API_REQUEST_URL } from '../../../common/api';
import {
    CREATE_ORDER_HEADER_BEGIN,
    CREATE_ORDER_HEADER_SUCCESS,
    CREATE_ORDER_HEADER_FAILURE,
} from './constants';

export function createOrderHeader(requestData = {}) {
    return (dispatch) => {
        dispatch({
            type: CREATE_ORDER_HEADER_BEGIN,
        });

        const promise = new Promise((resolve, reject) => {
            // console.log('RequestData.orderHeader-->' + JSON.stringify(requestData.orderHeader));
            // console.log('RequestData.products-->' + JSON.stringify(requestData.products));
            const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
            const doRequest = axios.post(`${API_REQUEST_URL}/salesforceOrder/createOrderHeader`, requestData, {
                headers: {
                    'Authorization': token
                }
            });
            
            doRequest.then(
                (res) => {
                    dispatch({
                        type: CREATE_ORDER_HEADER_SUCCESS,
                        data: res.data,
                    });
                    resolve(res);
                },
                (err) => {
                    dispatch({
                        type: CREATE_ORDER_HEADER_FAILURE,
                        data: { error: err },
                    });
                    reject(err);
                },
            );
        });
        return promise;
    };
}
export function reducer(state, action) {
    switch (action.type) {
        case CREATE_ORDER_HEADER_BEGIN:
            return {
                ...state,
                createOrderHeaderPending: true,
                createOrderHeaderError: null,
            };

        case CREATE_ORDER_HEADER_SUCCESS:
            return {
                ...state,
                orderHeader: action.data.res,
                itemError: action.data.itemError,
                itemSucc: action.data.itemSucc,
                createOrderHeaderPending: false,
                createOrderHeaderError: null,
            };

        case CREATE_ORDER_HEADER_FAILURE:
            return {
                ...state,
                createOrderHeaderPending: false,
                createOrderHeaderError: action.data.error,
            };

        default:
            return state;
    }
}
