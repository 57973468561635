import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { HeadPanel } from './';
import { Menu } from './';
import decode from 'jwt-decode'
import { getOrderToken, getAccountToken, getUSBLOgmToken, getSalesforceOrderToken,getAuthrization, getWillCallTimesToken, getOrderReferenceToken,getAsohToken } from './redux/actions';
import { redirectUrl } from '../../common/api';
import { ErrorPage } from '../common';
import { AUTH_ERROR_MSG } from './redux/constants';
class AppComponent extends React.Component {
  componentDidMount() {
    const { auth,getWillCallTimesToken } = this.props;
    if (window.location.href.indexOf('id_token') > -1) {
      let newAuth = JSON.parse(JSON.stringify(auth));
      const idToken = window.location.href.match(/(id_token)\=([\S\s]*?)\&/)[2];//eslint-disable-line
      const user = decode(idToken).email;
      const index = user ? user.indexOf('@') : -1;
      const userName = index > -1 ? user.slice(0, index) : '';
      // const userId = decode(idToken)['cognito:username'];
      newAuth.cognitoIdToken = idToken;
      newAuth.validUser = true;
      newAuth.user = user;
      // const index = userId ? userId.indexOf('@') : -1;
      // const netWorkId = index > -1 ? userId.slice(0, index) : '';
      newAuth.userId = userName;
      this.props.getAuthrization(newAuth);
    } else if(!auth.cognitoIdToken) {
      window.location.assign(redirectUrl);
    }
    // this.props.getOrderToken();
    // this.props.getAccountToken();
    // this.props.getUSBLOgmToken();
    // this.props.getSalesforceOrderToken();
    // getWillCallTimesToken();
  }
  componentWillReceiveProps(nextProps) {
    this.resetSession(nextProps);
    const { auth } = nextProps;
    const {getOrderToken,getAccountToken,getUSBLOgmToken,getWillCallTimesToken,getOrderReferenceToken,getAsohToken} = this.props;
    if (this.props.auth !== auth) {
      getOrderToken();
      // getAccountToken();
      // getUSBLOgmToken();
      // getWillCallTimesToken();
      // getOrderReferenceToken();
      getAsohToken();
    }
  }
  resetSession =(nextProps) => {
    const {
      fetchOverrideChartDetailError,
      fetchOverrideChartReportError,
      fetchReferenceIdError,
      fetchWillCallTimesError,
      fetchSupplierInfoError,
      fetchOpcosInfoError,
      fetchCustomerInfoError,
      createMuleOrderError,
      fetchProductInfoError,
      getAccountTokenError,
      getAsohTokenError,
      getOrderReferenceTokenError,
      getOrderTokenError,
      getUSBLOgmTokenError,
      getWillCallTimesTokenError,
      fetchAsohError,
      fetchBulkAsohError,
    } = nextProps;
    //const isErrorPage = getOrderTokenError || getAccountTokenError || getUSBLOgmTokenError || getWillCallTimesTokenError || getOrderReferenceTokenError;
    if(fetchOpcosInfoError === AUTH_ERROR_MSG || fetchSupplierInfoError === AUTH_ERROR_MSG
        || fetchCustomerInfoError === AUTH_ERROR_MSG || fetchWillCallTimesError === AUTH_ERROR_MSG 
        || fetchProductInfoError === AUTH_ERROR_MSG || fetchReferenceIdError === AUTH_ERROR_MSG
        || fetchOverrideChartReportError === AUTH_ERROR_MSG || fetchOverrideChartDetailError === AUTH_ERROR_MSG 
        || createMuleOrderError === AUTH_ERROR_MSG
        || getAccountTokenError === AUTH_ERROR_MSG || getAsohTokenError === AUTH_ERROR_MSG || getOrderReferenceTokenError === AUTH_ERROR_MSG
        || getOrderTokenError === AUTH_ERROR_MSG || getUSBLOgmTokenError === AUTH_ERROR_MSG || getWillCallTimesTokenError === AUTH_ERROR_MSG
        || createMuleOrderError === AUTH_ERROR_MSG || fetchAsohError === AUTH_ERROR_MSG || fetchBulkAsohError === AUTH_ERROR_MSG) {
        window.location.assign(redirectUrl);
    }
  }
  render() {
    return (
      <div className="home-app">
        <HeadPanel />
        <Menu />
        <div className="page-container">{this.props.children}</div>
      </div>
    );
  }
}

AppComponent.propTypes = {
  getOrderToken: PropTypes.func,
  getAccountToken: PropTypes.func,
  getUSBLOgmToken: PropTypes.func,
};

AppComponent.defaultProps = {};
const mapStateToProps = (state) => {
  const {
   auth,
   getOrderTokenError,
   getAccountTokenError,
   getUSBLOgmTokenError,
   getSalesforceTokenError,
   getWillCallTimesTokenError,
   getOrderReferenceTokenError,
   getAsohTokenError,
  } = state.home;
  const {
    fetchOverrideChartDetailError,
    fetchOverrideChartReportError,
    fetchReferenceIdError,
    fetchWillCallTimesError,
    fetchSupplierInfoError,
    fetchOpcosInfoError,
    fetchCustomerInfoError,
    createMuleOrderError,
    fetchProductInfoError,
    fetchAsohError,
    fetchBulkAsohError,
  } = state.order;
  return {
   auth,
   getOrderTokenError,
   getAccountTokenError,
   getUSBLOgmTokenError,
   getSalesforceTokenError,
   getWillCallTimesTokenError,
   getOrderReferenceTokenError,
   getAsohTokenError,
   fetchOverrideChartDetailError,
   fetchOverrideChartReportError,
   fetchReferenceIdError,
   fetchWillCallTimesError,
   fetchSupplierInfoError,
   fetchOpcosInfoError,
   fetchCustomerInfoError,
   createMuleOrderError,
   fetchProductInfoError,
   fetchAsohError,
   fetchBulkAsohError,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getOrderToken: () => {
      dispatch(getOrderToken());
    },
    getAccountToken: () => {
      dispatch(getAccountToken());
    },
    getUSBLOgmToken: () => {
      dispatch(getUSBLOgmToken());
    },
    getSalesforceOrderToken: ()=>{
      dispatch(getSalesforceOrderToken());
    },
    getAuthrization: (auth)=>{
      dispatch(getAuthrization(auth));
    },
    getWillCallTimesToken: ()=>{
      dispatch(getWillCallTimesToken());
    },
    getOrderReferenceToken: ()=>{
      dispatch(getOrderReferenceToken());
    },
    getAsohToken: ()=>{
      dispatch(getAsohToken());
    },
  };
};

const App = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppComponent);

export default App;
