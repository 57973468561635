import {
  UPDATE_DELIVERY_METHOD,
  CLEAR_DELIVERY_METHOD
} from './constants';

export function updateDeliveryMethod(fieldName, value) {
  return (dispatch) => {
    dispatch({
      type: UPDATE_DELIVERY_METHOD,
      data: {'fieldName': fieldName, 'value': value},
    });
  };
}

export function clearDeliveryMethod() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_DELIVERY_METHOD
    });
  };
}

export function reducer(state, action) {
  switch (action.type) {
    case UPDATE_DELIVERY_METHOD:
      return {
        ...state,
        [action.data.fieldName]: action.data.value
      };

    case CLEAR_DELIVERY_METHOD:
      return {
        ...state,
        willCall: false,
        routeToCustomer: false,
        // willCallTimes: []
      };

    default:
      return state;
  }
}
