import axios from 'axios';
import { API_REQUEST_URL } from '../../../common/api';
import {
    FETCH_PRICE_BOOK_ENTRY_BEGIN,
    FETCH_PRICE_BOOK_ENTRY_SUCCESS,
    FETCH_PRICE_BOOK_ENTRY_FAILURE,
} from './constants';
import { createOrderItem } from './createOrderItem';


export function fetchPriceBookEntry(opco,productNumber) {
    return (dispatch) => {
        dispatch({
            type: FETCH_PRICE_BOOK_ENTRY_BEGIN,
        });

        const promise = new Promise((resolve, reject) => {
            const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`
            // console.log('salesForceToken-->'+ token);
            const doRequest = axios.get(`${API_REQUEST_URL}/salesforceOrder/priceBookEntry?opco=${opco}&supc=${productNumber}`, {
                headers: {
                  'Authorization': token,
                }
              });
            doRequest.then(
                (res) => {
                    //call createOrderItem
                    // const itemDetail = {
                    //     "Line_Number__c": 1,
                    //     "OrderId": "8012i000003IqX9AAK",
                    //     "Customer_Response__c": "Price",
                    //     "Product2": {
                    //         "Unique_ID__c": "067-0010298"
                    //     },
                    //     "PriceBookEntryId": "01u4400000A4BfHAAV",
                    //     "UnitPrice": 10.0,
                    //     "Quantity": 1
                    // }
                    //dispatch(createOrderItem(itemDetail));
                    dispatch({
                        type: FETCH_PRICE_BOOK_ENTRY_SUCCESS,
                        data: res.data,
                    });
                    resolve(res);
                },
                (err) => {
                    dispatch({
                        type: FETCH_PRICE_BOOK_ENTRY_FAILURE,
                        data: { error: err },
                    });
                    reject(err);
                },
            );
        });

        return promise;
    };
}
export function reducer(state, action) {
    switch (action.type) {
        case FETCH_PRICE_BOOK_ENTRY_BEGIN:
            return {
                ...state,
                priceBookEntryPending: true,
                priceBookEntryError: null,
            };

        case FETCH_PRICE_BOOK_ENTRY_SUCCESS:
            return {
                ...state,
                priceBookEntry: action.data,
                priceBookEntryPending: false,
                priceBookEntryError: null,
            };

        case FETCH_PRICE_BOOK_ENTRY_FAILURE:
            return {
                ...state,
                priceBookEntryPending: false,
                priceBookEntryError: action.data.error,
            };

        default:
            return state;
    }
}
