import Amplify from 'aws-amplify';
import {
    DEV_API_REQUEST_URL, QA_API_REQUEST_URL, PROD_API_REQUEST_URL, LOCAL_API_REQUEST_URL,
    DEV_WS_REQUEST_SITES_URL,QA_WS_REQUEST_SITES_URL, PROD_WS_REQUEST_SITES_URL,
} from './constantsConfig';

let API_REQUEST_URL = DEV_API_REQUEST_URL;
let WS_REQUEST_SITES_URL = DEV_WS_REQUEST_SITES_URL;
let config = require('../config/config.js');
switch (process.env.REACT_APP_ICT_ENV) {
    case 'development':
        API_REQUEST_URL = DEV_API_REQUEST_URL;
        WS_REQUEST_SITES_URL = DEV_WS_REQUEST_SITES_URL;
        config = require('../config/config-dev.js');
        break;
    case 'qa':
        API_REQUEST_URL = QA_API_REQUEST_URL;
        WS_REQUEST_SITES_URL = QA_WS_REQUEST_SITES_URL;
        config = require('../config/config-qa.js');
        break;
    case 'production':
        API_REQUEST_URL = PROD_API_REQUEST_URL;
        WS_REQUEST_SITES_URL = PROD_WS_REQUEST_SITES_URL;
        config = require('../config/config-prod.js');
        break;
    default:
        API_REQUEST_URL = LOCAL_API_REQUEST_URL;
        WS_REQUEST_SITES_URL = DEV_WS_REQUEST_SITES_URL;
        config = require('../config/config.js');
}
Amplify.configure({
    Auth: {
        mandatorySignIn: true,
        region: config.default.cognito.REGION,
        userPoolId: config.default.cognito.USER_POOL_ID,
        identityPoolId: config.default.cognito.IDENTITY_POOL_ID,
        userPoolWebClientId: config.default.cognito.APP_CLIENT_ID,
        oauth: config.default.oauth
    },
    API: {
        endpoints: [
            {
                name: config.default.endpoints[0].name,
                endpoint: config.default.endpoints[0].endpoint,
                service: config.default.endpoints[0].service,
                region: config.default.endpoints[0].region
            }
        ]
    }
});
const redirectUrl = 'https://' + config.default.oauth.domain + '/oauth2/authorize?redirect_uri=' +
  config.default.oauth.redirectSignIn + '&response_type=' + config.default.oauth.responseType + '&client_id=' + config.default.cognito.APP_CLIENT_ID;
export { API_REQUEST_URL, WS_REQUEST_SITES_URL, config,redirectUrl };