export const BANNER = 'Will Call hours are 8am to 4:30pm Monday through Friday. Orders for will call must be placed by 12pm for same day pickup. Orders placed after 12pm will be available at will call the next business day at 8am.';
export const DATE_FROMAT_MM_DD_YYYY = 'MM-DD-YYYY';
export const DATE_FROMAT_MM_DD_YYYY_HH_MM_SS = 'MM-DD-YYYY HH:mm:ss';
export const DATE_FROMAT_YYYY_MM_DD = 'YYYY-MM-DD';
export const DATE_FROMAT_HH_MM_SS = 'HH:mm:ss';
export const FIVE_DIGIT_MAX_NUM = 99999;
export const FIVE_DIGIT_MIN_NUM = 10000;

export const USELESS_OPCO_LIST = [
  { opcoNo: "042", opcoName: "Central Warehouse", },  //Sysco Central Warehouse
  { opcoNo: "134", opcoName: "IFG - Plant City", },   //Sysco International Food Group
  { opcoNo: "146", opcoName: "Reno Central Whse", },   //Sysco Central Warehouse West
  { opcoNo: "147", opcoName: "Central Warehouse-SouthEast", },   //Sysco Central Warehouse Southeast
  { opcoNo: "148", opcoName: "NE Central Warehouse", },   //Sysco Central Warehouse Northeast
  { opcoNo: "240", opcoName: "", },   //Sysco Corporate
  { opcoNo: "335", opcoName: "Bahama Food Services", },   //Sysco Bahamas
  { opcoNo: "344", opcoName: "IFG - Jacksonville", },   //Sysco International Food Group
];
export const DOM_VIEW_NAME_LOCATION ="Location";