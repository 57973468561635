import React from 'react';
import { useSelector } from 'react-redux';
import { Divider } from 'antd';
export default function HeadPanel() {
  const { auth } = useSelector(state => ({
    auth: state.home.auth,
  }));
  const email = auth.user;
  const index = email ? email.indexOf('@') : -1;
  const userName = index > -1 ? email.slice(0, index) : '';
  return (
    <header className='head-panel'>
      <div className='header-left font-size-28'>
        <img src={require('../../images/logo.png')} className='sysco-logo' alt='sysco-logo' />
        <Divider className='custom-divider' type="vertical" />
        <div>Broker and Supplier Food Product Form</div>
      </div>
      <div className='header-right font-size-18'>
        {` Welcome, ${userName} `}
      </div>
    </header>
  );
};

HeadPanel.propTypes = {};
HeadPanel.defaultProps = {};
