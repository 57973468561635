import { message } from 'antd';
import axios from 'axios';
import _ from 'lodash';
import { API_REQUEST_URL } from '../../../common/api';
import { DOM_ORDER_RESPONSE } from '../component/MessageConstants';
import {
    CREATE_MULE_ORDER_BEGIN,
    CREATE_MULE_ORDER_SUCCESS,
    CREATE_MULE_ORDER_FAILURE,
    CLEAR_CREATE_ORDER_ERROR,
    CLEAR_MULE_ORDER,
} from './constants';

export function createMuleOrder(requestData = {}) {
    return (dispatch,getState) => {
        dispatch({
            type: CREATE_MULE_ORDER_BEGIN,
        });

        const promise = new Promise((resolve, reject) => {
            const cognitoIdToken = getState().home.auth.cognitoIdToken;
            const cognitoToken = `Bearer ${cognitoIdToken}`;
            const token = `Bearer ${window.sessionStorage.getItem("qaToken")}`;
            const isDomEnabled = getState().order.isDomSite;
            const doRequest = axios.post(`${API_REQUEST_URL}/order/createMuleOrder`, requestData, {
                headers: {
                    'Authorization': token,
                    'cognitoToken': cognitoToken
                }
            });
            doRequest.then(
                (res) => {
                    let data = res.data;
                    if(isDomEnabled){
                        let uomNumber = requestData.uomNumber + '';
                        const domMessage = DOM_ORDER_RESPONSE.replace('#',uomNumber.padStart(7,'0'))
                        data = {
                            message: domMessage
                        }
                    }
                    dispatch({
                        type: CREATE_MULE_ORDER_SUCCESS,
                        data: data,
                    });
                    resolve(res);
                },
                (err) => {
                    const errorMsg = err && err.response ? err.response.data.message : 'There is an error. Please refresh page or contact administrator.';
                    dispatch({
                        type: CREATE_MULE_ORDER_FAILURE,
                        data: { error: errorMsg },
                    });
                    reject(err);
                },
            );
        });
        return promise;
    };
}
export function clearCreateOrderError() {
    return (dispatch) => {
      dispatch({
        type: CLEAR_CREATE_ORDER_ERROR,
      });
    };
  }
  export function clearMuleOrder() {
    return (dispatch) => {
      dispatch({
        type: CLEAR_MULE_ORDER,
      });
    };
  }
export function reducer(state, action) {
    switch (action.type) {
        case CREATE_MULE_ORDER_BEGIN:
            return {
                ...state,
                createMuleOrderPending: true,
                createMuleOrderError: null,
            };

        case CREATE_MULE_ORDER_SUCCESS:
            return {
                ...state,
                muleOrder: action.data,
                createMuleOrderPending: false,
                createMuleOrderError: null,
            };

        case CREATE_MULE_ORDER_FAILURE:
            return {
                ...state,
                createMuleOrderPending: false,
                createMuleOrderError: action.data.error,
            };
        case CLEAR_CREATE_ORDER_ERROR:
            return {
                ...state,
                createMuleOrderError: null,
            };

        case CLEAR_MULE_ORDER:
            return {
                ...state,
                muleOrder: '',
            };
        default:
            return state;
    }
}
