const initialState = {
    orderToken: {},
    accountToken: {},
    auth: {
        cognitoIdToken: null,
        validUser: false,
        user: '',
        userId:'',
      },
    usblOgmToken: {},
    salesforceToken: {},
    willCallTimesToken: {},
    orderReferenceToken: {},
    getOrderTokenError: null,
    getAccountTokenError: null,
    getUSBLOgmTokenError: null,
    getSalesforceTokenError: null,
    getWillCallTimesTokenError: null,
    getOrderReferenceTokenError: null,
    getAsohTokenError: null,
    getOrderTokenPending: false,
    getAsohTokenPending: false,

};

export default initialState;
