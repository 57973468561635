// Initial state is the place you define all initial values for the Redux store of the feature.
// In the 'standard' way, initialState is defined in reducers: http://redux.js.org/docs/basics/Reducers.html
// But when application grows, there will be multiple reducers files, it's not intuitive what data is managed by the whole store.
// So Rekit extracts the initial state definition into a separate module so that you can have
// a quick view about what data is used for the feature, at any time.

// NOTE: initialState constant is necessary so that Rekit could auto add initial state when creating async actions.
const initialState = {
  opcos: {},
  supplier: {},
  customer: {},
  products: [{
    index: '',
    id: '',
    cs: '',
    spl: '',
    name: ''
  }],
  overrideChartReport:{},
  overrideChartDetail:[],
  priceBookEntry: {},
  orderHeader: {},
  orderItem: {},
  createOrderItemError: null,
  createOrderHeaderError: null,
  itemError: [],
  itemSucc: [],
  createOrderHeaderPending: false,
  fetchProductInfoError: null,
  fetchProductInfoPending: false,
  createOrderItemPending: false,
  invalidProducts: [],
  supplierErrorStatus: '',
  customerErrorStatus: '',
  fetchWillCallTimesPending: false,
  willCallTimes: [],
  setItemQuantityPending: false,
  createMuleOrderPending: false,
  muleOrder:'',
  fetchOverrideChartDetailError: null,
  fetchOverrideChartReportError: null,
  fetchReferenceIdError: null,
  fetchWillCallTimesError: null,
  fetchSupplierInfoError: null,
  fetchOpcosInfoError: null,
  fetchCustomerInfoError: null,
  createMuleOrderError: null,
  sites:[],
  fetchSiteInfoPending: false,
  asohValidationMsg: [],
  isDomSite: false,
  fetchIsDomSitePending: false,
  fetchAsohError: null,
  fetchAsohPending: false,
  fetchBulkAsohPending: false,
  fetchReferenceIdPending: false,
};

export default initialState;
