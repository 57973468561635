import { PROD_ENDPOINT, PROD_AUTH_DOMAIN, PROD_REDIRECT } from '../common/constantsConfig';
export default {
	cognito: {
		REGION: 'us-east-1',
		USER_POOL_ID: 'us-east-1_ZC8ah4msH',
		APP_CLIENT_ID: '5m7moct8kokkmn1uoumgqpnogj',
		IDENTITY_POOL_ID: 'us-east-1:d4e86101-9486-40cb-b261-760fedd2c0b7'
	},
	endpoints: [			
		{
			name: 'vendorbrokerui',
			endpoint: PROD_ENDPOINT,
			service: "lambda",
			region: "us-east-1"
		}
	],
	oauth:{
		// Domain name
		domain: PROD_AUTH_DOMAIN,
	
		// Authorized scopes
		scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
	
		// Callback URL
		redirectSignIn: PROD_REDIRECT, // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
		// Sign out URL
		redirectSignOut: PROD_REDIRECT, // or 'exp://127.0.0.1:19000/--/', 'myapp://main/'
	
		// 'code' for Authorization code grant, 
		// 'token' for Implicit grant
		// Note that REFRESH token will only be generated when the responseType is code
		responseType: 'token',
	
		// optional, for Cognito hosted ui specified options
		options: {
			// Indicates if the data collection is enabled to support Cognito advanced security features. By default, this flag is set to true.
			AdvancedSecurityDataCollectionFlag: true
		},
	
		urlOpener: null
	}
};
