import _ from 'lodash';
export const sortObject = (obj, key, order) => {
    //order - true: Asc; false: dec
    return obj.sort(function (a, b) {
        var x = a[key]; var y = b[key];
        if (order) {
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
        } else {
          return ((x < y) ? ((x > y) ? 1 : 0) : -1);
        }
      });
}
export const spliceInvalidProduct = (orgObject,action) =>{
  let invalid = JSON.parse(JSON.stringify(orgObject));
    if(!_.isEmpty(action.invalidProduct)){
      let invalidIndex = invalid.findIndex(item=>item.index === action.data.index);
      if(invalidIndex === -1){
        //add invalidProduct to state
        invalid.splice(0,0,action.invalidProduct);
      }else{
        invalid.splice(invalidIndex,1,action.invalidProduct);
      }
    }else{
      let invalidIndex = invalid.findIndex(item=>item.index === action.data.index);
      if(invalidIndex != -1){
        invalid.splice(invalidIndex,1);
      }
    }
  return invalid;
}
export const sortFiltersListByKey = (data, key) => {
  if(key) {
    return data.sort((itemOne, itemTwo) => {
      return itemOne[key] ? itemOne[key].localeCompare(itemTwo[key]) : 0;
    });
  }
  if(data) {
    return data.sort((itemOne, itemTwo) => {
      return itemOne ? itemOne.localeCompare(itemTwo) : 0;
    });
  }
  return data;
}
export const addAsohErrorMsg = (orgObject,asohMessage,isDomBothCSEA) =>{
  let orgMsg = JSON.parse(JSON.stringify(orgObject));
    if(!_.isEmpty(asohMessage)){
      let index = orgMsg.findIndex(item=>item.index === asohMessage.index && item.unit === asohMessage.unit && item.id === asohMessage.id);
      if(index > -1){
        //if this item exist in error message array and new message is empty, will remove error message for this item
        if (_.isEmpty(asohMessage.message)) {
          if (isDomBothCSEA) {
            orgMsg = orgMsg.filter(item => {
              return item.index !== asohMessage.index;
            });
          }else{
            orgMsg.splice(index, 1);
          }
        }
      }else{
        //add new item,if message is not empty,will add to meaage array
        if(!_.isEmpty(asohMessage.message)){
          // if (isDomBothCSEA) {
          //   orgMsg = orgMsg.filter(item => {
          //     return item.index !== asohMessage.index;
          //   });
          // }else{
            orgMsg.splice(0,0,asohMessage);
          // }
        } else {
          if (isDomBothCSEA) {
            orgMsg = orgMsg.filter(item => {
              return item.index !== asohMessage.index;
            });
          }
        }
      }
    }

  return orgMsg;
}